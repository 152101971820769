import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ModalProps as AntDModalProps,
  Divider,
  InputNumber,
  Table,
  TableColumnsType,
  Tag,
  Typography,
  notification,
} from "antd";
import Modal from "./Modal";
import styles from "./PrutModal.module.css";
import { ProjectContribution, PrutByUser, PreviousPrut } from "../types/Prut";
import { DataTableHelper } from "../helpers/DataTableHelper";
import { fetchPrutByUser, updatePreviousByUsers } from "../api/PrutAPI";
import { ProjectHelper } from "../helpers/ProjectHelper";
import { useTranslation } from "react-i18next";
import { formatPostData, formatTotalPercentage } from "../helpers/PrutHelper";
import { Pic } from "../types/Pic";
import { fetchPics } from "../api/PicAPI";
import Loader from "./Loader";
import PreviousPrutBanner from "./PreviousPrutBanner";

interface PreviousPrutModalProps extends AntDModalProps {
  id: string;
  upn: string;
  showModal: boolean;
  onCloseModal: VoidFunction;
  setReloadTable: (reloadTable: boolean) => void;
}

type PreviousPrutStatsType = {
  sumArray: number[],
  projectContribution: number
}

const PreviousPrutModal: React.FC<PreviousPrutModalProps> = ({
  id,
  upn,
  showModal,
  onCloseModal,
  setReloadTable,
}) => {
  const { Text, Title } = Typography;
  const { t } = useTranslation();
  const [previousPrutStats, setPreviousPrutStats] = useState<PreviousPrutStatsType>({
    sumArray: [],
    projectContribution: 0,
  });
  const [enableSaveBtn, setEnableSaveBtn] = useState<boolean>(false);
  const [postData, setPostData] = useState<PreviousPrut[]>([]);
  const [previousValue, setPreviousValue] = useState<number>(0);
  const [pics, setPics] = useState<Array<Pic>>([]);
  const [prut, setPrut] = useState<PrutByUser>({
    currentWeekNumber: 1,
    currentWeekYear: 1,
    currentWeekStartDate: "",
    currentWeekEndDate: "",
    previousWeekNumber: 1,
    previousWeekYear: 1,
    projects: [],
  });
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const updatedValueRef = useRef<{ [id: string]: number }>(
    {}
  );

  const isNotFullHundred = useMemo(() => {
    return previousPrutStats.projectContribution !== 100;
  }, [previousPrutStats]);

  const projectContributionColumns: TableColumnsType<ProjectContribution> = [
    {
      title: "Project Title",
      dataIndex: ["project", "name"],
      width: "19.75rem",
      ellipsis: true,
      sorter: (a, b) =>
        DataTableHelper.stringSorter(a.project.name, b.project.name),
      render: (projectTitle: string) => <Text>{projectTitle}</Text>,
    },
    {
      title: "Project Status",
      dataIndex: ["project", "status"],
      width: "10rem",
      sorter: (a, b) =>
        DataTableHelper.sorter(a.project.status, b.project.status),
      filters: ProjectHelper.getStatusFilterOptions(),
      onFilter: (value, record) =>
        DataTableHelper.filter(value, record.project.status),
      render: (status: number) => {
        return (
          <Tag color={ProjectHelper.getStatusColor(status)} key={status}>
            {t(ProjectHelper.getStatusTagName(status))}
          </Tag>
        );
      },
    },
    {
      title: "Project In-Charge",
      dataIndex: ["project", "pic", "name"],
      width: "11.5rem",
      sorter: (a, b) =>
        DataTableHelper.stringSorter(a.project.pic.name, b.project.pic.name),
      filters: ProjectHelper.getPicFilterOptions(pics),
      onFilter: (value, record) =>
        DataTableHelper.filter(value, record.project.pic.id),
    },
    {
      title: "Previous PRUT",
      dataIndex: "previous",
      width: "9rem",
      sorter: (a, b) => {
        const recordA = updatedValueRef.current[a.project.id], recordB = updatedValueRef.current[b.project.id];
        return DataTableHelper.sorter(recordA ?? a.previous, recordB ?? b.previous)
      },
      defaultSortOrder: "descend",
      render: (_, record) => (
        <div className={styles.current_prut_cell_container}>
          <InputNumber
            data-testid="current_value_input"
            defaultValue={
              postData.find((project) => project.id === record.project.id)
                ?.previous ?? record.previous
            }
            min={0}
            max={100}
            controls={false}
            onFocus={(e) => setPreviousValue(Number(e.target.value))}
            onChange={(value) => {
              setEnableSaveBtn(true);
              setPostData(
                formatPostData(
                  [
                    {
                      id: record.project.id,
                      previous: Number(value),
                    },
                  ],
                  postData
                )
              );
              setPreviousPrutStats(
                formatTotalPercentage(
                  Number(value),
                  previousPrutStats.sumArray,
                  previousValue
                )
              );
              setPreviousValue(Number(value));
              updatedValueRef.current[record.project.id] = Number(value);
            }}
            className={styles.current_prut_cell_input}
            status={isNotFullHundred ? "error" : ""}
          />
          %
        </div>
      ),
    },
  ];

  const handleSubmit = () => {
    setIsSavingChanges(true);
    const data = [
      {
        id: id,
        previousWeekYear: prut.previousWeekYear,
        previousWeekNumber: prut.previousWeekNumber,
        projects: postData,
      },
    ];
    updatePreviousByUsers(data).then((response) => {
      if (response.status === "success") {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: "Project utilisation saved!",
          description: `Previous PRUT for ${upn} is now updated.`,
          className: styles.alert_success,
        });
        setReloadTable(true);
        setPostData([]);
        updatedValueRef.current = {};
        handleClose();
        onCloseModal();
      } else {
        notification.open({
          type: "error",
          placement: "topRight",
          message: "Error!",
          description: `Error saving project utilisation for ${upn}.`,
          className: styles.alert_error,
        });
      }
    }).catch(() => {
      notification.open({
        type: "error",
        placement: "topRight",
        top: 84,
        message: t("Action Failed"),
        description: t(
          "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
        ),
        className: "notification-error",
      });
    }).finally(()=> {
      setEnableSaveBtn(false);
      setIsSavingChanges(false);
    });
  };

  const handleCancel = () => {
    setPostData([]);
    updatedValueRef.current = {};
    setEnableSaveBtn(false);
    handleClose();
    onCloseModal();
  };

  const handleClose = () => {
    // Set everything to 0 when closing
    setPreviousPrutStats({
      sumArray: [],
      projectContribution: 0,
    });
    setPrut({
      currentWeekYear: 0,
      currentWeekNumber: 0,
      previousWeekYear: 0,
      previousWeekNumber: 0,
      currentWeekStartDate: "2024-01-01T16:00:00.000Z",
      currentWeekEndDate: "2024-01-01T15:59:59.000Z",
      projects: [],
    });
    setLoading(true);
  };

  useEffect(() => {
    fetchPics().then((response) => {
      setPics(response.data.pics);
    });
  }, [showModal]);

  useEffect(() => {
    if (id !== "") {
      fetchPrutByUser(id).then((response) => {
        const { prut } = response.data;
        setPrut(prut);
        setPreviousPrutStats({
          sumArray: prut.projects.map(
            (data: { previous: number }) => data.previous
          ),
          projectContribution: prut.totalPreviousContribution,
        });
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, showModal]);

  return (
    <Modal
      afterClose={handleClose}
      title={<Title level={5}>Edit Previous PRUT</Title>}
      data-testid="prut_modal"
      open={showModal}
      onOk={handleSubmit}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: !enableSaveBtn || isNotFullHundred,
      }}
      okText="Save Changes"
      size={"large"}
      destroyOnClose
      confirmLoading={isSavingChanges}
    >
      {loading ? (
        <Loader className={styles.loading_container} />
      ) : (
        <div className={styles.modal_content_container}>
          <Text>
            Please update the Previous PRUT for <b>{upn}</b> below and ensure
            that the Total Previous PRUT amounts to 100%.
          </Text>
          <Divider className={styles.no_margin} />
          <PreviousPrutBanner prut={prut} />
          <Table
            columns={projectContributionColumns}
            dataSource={prut.projects}
            rowKey={(record) => record.project.id}
            pagination={false}
          />
          <div className={styles.footer_container}>
            <div className={styles.footer_text}>
              <Text strong>Total Previous PRUT </Text>
            </div>
            <div className={styles.total_percentage}>
              <Text data-testid="total_contribution">
                {previousPrutStats.projectContribution}{" "}
              </Text>
            </div>
            %
          </div>
          {isNotFullHundred && (
            <div
              data-testid="error_message"
              className={styles.message_container}
            >
              <Text type="danger">
                The changes cannot be saved as the Total Previous PRUT does not
                amount to 100%. Please adjust the utilisation accordingly before
                attempting to save it.
              </Text>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default PreviousPrutModal;
