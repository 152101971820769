import React from 'react'
import { ButtonProps, Modal, notification, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteMember } from '../api/LeadAPI';
import { LeadGetDetails, UserLeadMapping } from '../types/Lead';

import styles from './DeleteMemberModal.module.css';
import Loader from './Loader';

import { CloseOutlined } from '@ant-design/icons';
import UpdateCommissionRole from './UpdateCommissionRole'

type UpdateRoleProps = {
  onRefetchData: VoidFunction,
  onCloseModal: VoidFunction,
  openModal: boolean,
  selectedDeleteMember: UserLeadMapping | null,
  lead: LeadGetDetails
};

const UpdateRole = ({
  lead,
  selectedDeleteMember,
  openModal,
  onRefetchData,
  onCloseModal,
}: UpdateRoleProps) => {

  const { Title, Text } = Typography;

  const [tempExistingMembers, setExistingMembers] = useState<UserLeadMapping[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showComissionContent, setShowComissionContent] = useState(false)
  const [comissionError, setCommissionError] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    const tempSum = tempExistingMembers.reduce((sum, row) => (row.commissionShare ? sum + row.commissionShare : sum), 0);

    if (tempSum !== 100) {
      setCommissionError('The changes cannot be saved as the Total commission does not amount to 100%. Please adjust accordingly before attempting to save it.')
    } else {
      setCommissionError('')
    }
  }, [
    tempExistingMembers
  ])

  const onModalOk =  async () => {
    if (!selectedDeleteMember?.id) return;

    if (!showComissionContent) {
      setShowComissionContent(true)
      return 
    }

    setLoading(true);

    await deleteMember({
      id: selectedDeleteMember.id,
      leadId: selectedDeleteMember.leadId,
      members: tempExistingMembers
    })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: "Member removed!",
          description: `${selectedDeleteMember.upn} has been successfully removed from ${lead.title}.`,
          className: styles.alert_success,
        });
        onRefetchData();
        onCloseModal()
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: "notification-error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onModalCancel = (e?: React.MouseEvent<HTMLElement>): void => {
    if (e) {
      const target = e.target as HTMLElement;
      if (target.tagName === 'svg' || target.className === 'ant-modal-close-x' ) {
        return onCloseModal()
      } else {
        if (!showComissionContent ){
          return onCloseModal()
        }
        setShowComissionContent(false)
      }
    }
  }

  useEffect(() => {
    if(!openModal){
      setShowComissionContent(false)
    }
  }, [openModal])

  useEffect(() => {
    if(openModal && selectedDeleteMember) {
      const tempFindIndex = lead.members.findIndex(member => {
        return member.upn === selectedDeleteMember.upn
      })

      const tempMembers = [ ... lead.members ]
      tempMembers.splice(tempFindIndex, 1)
      
      setExistingMembers(tempMembers)
    }
  }, [lead, openModal, selectedDeleteMember])

  return (
    <Modal
      width={800}
      title={<Title level={5}>
        {t('Delete Member')}
      </Title>}
      data-testid="delete-member-modal"
      open={openModal}
      onOk={() => onModalOk()}
      onCancel={onModalCancel}
      cancelText={'Cancel'}
      okText={ !showComissionContent ? t('Yes, Delete') : t('Save Changes')}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: showComissionContent 
            ? comissionError
            : false,
        } as ButtonProps
      }
      closeIcon={<CloseOutlined data-testid='close-modal-button'/>}
      destroyOnClose
    >
      <>
        {
          loading && (
            <Loader className={styles.custom_loader}/>
          )
        }
        {
          (!loading &&
          showComissionContent) && (
            <UpdateCommissionRole
              lead={lead}
              hideRoleColumn={true}
              tempExistingMembers={tempExistingMembers}
              setExistingMembers={(val: UserLeadMapping[]) => setExistingMembers(val)}
              setMadeChanges={() => {}}
              comissionError={comissionError}
            />
          )
        }
        {
          (!loading &&
          !showComissionContent) && (
            <>
              <Text>
                {`Are you sure you want to remove ${selectedDeleteMember?.upn} from this lead? If this member has a ‘member’ role, their commission percentage will be deleted. Please edit the remaining distribution to ensure the total adds up to 100% after confirmation.`}
              </Text>
            </>   
          )
        }
      </>
    </Modal>
  );
};

export default UpdateRole;
