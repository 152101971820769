import React from 'react'
import { Modal, Typography, notification } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeadGetDetails, UserLeadMapping } from '../types/Lead';

import { deleteConsultant } from '../api/LeadAPI';
import styles from './DeleteConsultantModal.module.css';
import Loader from './Loader';

type AddMembersProps = {
  onRefetchData: VoidFunction,
  onCloseModal: VoidFunction,
  openModal: boolean,
  selectedDeleteConsultant: UserLeadMapping | null,
  lead: LeadGetDetails
};

const AddMembers = ({
  lead,
  selectedDeleteConsultant,
  openModal,
  onRefetchData,
  onCloseModal,
}: AddMembersProps) => {

  const [loading, setLoading] = useState<boolean>()
  const { Title, Text } = Typography;
  
  const { t } = useTranslation();

  const onModalOk =  async () => {
    setLoading(true);

    if (!selectedDeleteConsultant?.id) return;

    await deleteConsultant({
      id: selectedDeleteConsultant.id,
      leadId: selectedDeleteConsultant.leadId,
    })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: "Member removed!",
          description: `${selectedDeleteConsultant.upn} has been successfully removed from ${lead.title}.`,
          className: styles.alert_success,
        });
        onRefetchData();
        onCloseModal()
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: "notification-error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const onModalCancel = () => onCloseModal()

  return (
    <Modal
      width={800}
      title={<Title level={5}>
        {t('Delete Member')}
      </Title>}
      data-testid="delete-consultant-modal"
      open={openModal}
      onOk={() => onModalOk()}
      onCancel={() => onModalCancel()}
      cancelText={'Cancel'}
      okText={ t('Yes, Delete')}
      destroyOnClose
    >
      {
        loading && (
          <Loader className={styles.custom_loader}/>
        )
      }
      {
        !loading && (
          <Text>
            {`Are you sure you want to remove ${selectedDeleteConsultant?.upn} from this lead? If this member has a ‘member’ role, their commission percentage will be deleted. Please edit the remaining distribution to ensure the total adds up to 100% after confirmation.`}
          </Text>
        )
      }
    </Modal>
  );
};

export default AddMembers;
