import React, { useEffect } from "react";
import {
  ModalProps as AntDModalProps,
  ButtonProps,
  Form,
  Input,
  Select,
  Alert,
  Typography,
} from "antd";
import Modal from "./Modal";
import styles from "./CreateLeadModal.module.css";
import { useTranslation } from "react-i18next";
import { useCreateLeadModal } from "../hooks";
import { LeadFormField } from "../types/Lead";
import { LeadType } from "../enum/LeadType";
import Decimal from "decimal.js";
import { FieldData } from "rc-field-form/lib/interface";

const { TextArea } = Input;

interface CreateLeadModalProps extends AntDModalProps {
  showModal: boolean;
  onRefetchData: VoidFunction;
  onClose: VoidFunction;
}

const MAX_LENGTH_INPUT = 100;

const MAX_LENGTH_DESCRIPTION = 2000;

const VALID_NUMBER_MAX_2D_REGEX = /^-?\d*(\.\d{0,2})?$/;
const VALID_TWO_OR_MORE_DECIMAL_NUMBER_REGEX = new RegExp(/^-?\d*\.\d{2,}$/);
const DOLLAR_VALUE_REGEX = new RegExp(/^-?\d+(?:,\d{3})*(?:\.\d{2,})?$/);

const LEAD_TYPES = [
  { label: "Operations & Support", value: LeadType.OPERATIONS_AND_SUPPORT },
  {
    label: "Implementation/Consultancy",
    value: LeadType.IMPLEMENTATION_OR_CONSULTANCY,
  },
];

const CreateLeadModal: React.FC<CreateLeadModalProps> = ({
  showModal,
  onRefetchData,
  onClose,
}) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm<LeadFormField>();
  const {
    submitButtonRef,
    buttonDisabled,
    setButtonDisabled,
    onModalOk,
    onCloseModal,
    onSubmit,
  } = useCreateLeadModal({ t, form, onRefetchData, onClose });

  const fieldsTo2decimal = ["totalContractValue", "totalCommissionValue"];

  useEffect(() => {
    showModal && form.resetFields();
  },[showModal])

  const onFieldChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    setButtonDisabled(
      form.getFieldsError().some((field) => field.errors.length > 0)
    );

    if (changedFields.length > 1) return;

    let fieldToValidate = { ...changedFields[0] };

    if (fieldToValidate?.errors?.[0]) {
      fieldToValidate = { ...fieldToValidate, errors: [] };
      const updatedFields = allFields.map((field: FieldData) => {
        if (field.name?.[0] === fieldToValidate.name?.[0]) {
          return fieldToValidate;
        }
        return field;
      });
      const hasErrors = updatedFields.some((field: FieldData) => field.errors && field.errors.length > 0);

      setButtonDisabled(hasErrors)
      form.setFields(updatedFields)
    }
  };

  const handleFieldsBlur = (event: React.FocusEvent<HTMLFormElement>) => {
    const [, fieldName] = event.target.id.split("_");
    const fieldValue: string = form.getFieldValue(fieldName);

    if (
      fieldValue &&
      fieldsTo2decimal.includes(fieldName) &&
      VALID_NUMBER_MAX_2D_REGEX.test(fieldValue)
    ) {
      const format2decimal = new Decimal(fieldValue).toFixed(2);
      // Format the number with commas as thousands separators
      const formatWithCommas = format2decimal.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      form.setFieldsValue({ [fieldName]: formatWithCommas });
    }
  };

  const handleFieldsOnFocus = (event: React.FocusEvent<HTMLFormElement>) => {
    const [, fieldName] = event.target.id.split("_");
    const fieldValue: string = form.getFieldValue(fieldName);

    if (fieldValue && fieldsTo2decimal.includes(fieldName)) {
      // Remove any non-numeric characters except for the decimal point and minus sign
      const onlyNumber = fieldValue.replace(/[^0-9.-]/g, "");
      form.setFieldsValue({ [fieldName]: onlyNumber });
    }
  };

  const handleNumberInputChange =
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;

      value = value.replace(/[^0-9.]/g, '');
      
      if (VALID_TWO_OR_MORE_DECIMAL_NUMBER_REGEX.test(value)) {
        //round down to 2 decimal places
        const numValue = new Decimal(value).toDecimalPlaces(
          2,
          Decimal.ROUND_DOWN
        );
        value = numValue.toFixed(2);
      }
      event.target.value = value;
    };

  return (
    <Modal
      title={<Title level={5}>Create Lead</Title>}
      data-testid="create_lead_modal"
      open={showModal}
      onOk={onModalOk}
      onCancel={onCloseModal}
      okText={t("Create")}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: buttonDisabled,
        } as ButtonProps
      }
      size={"medium"}
      destroyOnClose
    >
      <Alert
        message="For leads with multiple commission milestones, please create a separate lead for each. Complete the fields below based on the specific milestone."
        type="info"
        showIcon
      />
      <div className={styles.modal_content_container}>
        <Form
          form={form}
          name="create-lead"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSubmit}
          onFieldsChange={onFieldChange}
          onBlur={handleFieldsBlur}
          onFocus={handleFieldsOnFocus}
          validateTrigger="onSubmit"
          labelWrap
        >
          <Form.Item
            label={t("Lead Title")}
            validateTrigger="onChange"
            name="title"
            rules={[
              {
                required: true,
                message: "This is a required field.",
                validateTrigger: [],
              },
              {
                whitespace: true,
                message: "Please enter at least one non-space character.",
                validateTrigger: [],
              },
            ]}
          >
            <Input
              data-testid="current_value_input"
              placeholder={t("Enter Lead Title")}
              maxLength={MAX_LENGTH_INPUT}
            />
          </Form.Item>

          <Form.Item
            label={t("Lead Type")}
            name="type"
            rules={[{ required: true, message: "This is a required field." }]}
            validateTrigger="onChange"
          >
            <Select
              className={styles.input}
              placeholder={t("Select Lead Type")}
              options={LEAD_TYPES}
            />
          </Form.Item>

          <Form.Item
            label={t("Deal / Contract Reference")}
            name="contractRef"
            initialValue=""
            rules={[
              {
                whitespace: true,
                message: "Please enter at least one non-space character.",
                validateTrigger: [],
              },
            ]}
          >
            <Input
              placeholder={t("Enter Deal / Contract Reference")}
              maxLength={MAX_LENGTH_INPUT}
            />
          </Form.Item>

          <Form.Item
            label={t("Total Contract Value")}
            name="totalContractValue"
            rules={[
              {
                pattern: DOLLAR_VALUE_REGEX,
                message: "Please enter a valid numerical value.",
              },
            ]}
          >
            <Input
              addonBefore="$"
              placeholder={t("Enter Total Contract Value")}
              onInput={handleNumberInputChange()}
            />
          </Form.Item>

          <Form.Item
            label={t("Total Commission Value")}
            name="totalCommissionValue"
            rules={[
              {
                pattern: DOLLAR_VALUE_REGEX,
                message: "Please enter a valid numerical value.",
              },
            ]}
          >
            <Input
              addonBefore="$"
              placeholder={t("Enter Total Commission Value")}
              onInput={handleNumberInputChange()}
            />
          </Form.Item>

          <Form.Item
            label={t("Description")}
            name="description"
            rules={[
              {
                whitespace: true,
                message: "Please enter at least one non-space character.",
                validateTrigger: [],
              },
            ]}
          >
            <TextArea
              data-testid="current_value_description"
              className={styles.description_input}
              placeholder={t("Enter Lead Description")}
              maxLength={MAX_LENGTH_DESCRIPTION}
              rows={5}
              showCount
            />
          </Form.Item>

          <input
            data-testid="submit-form"
            ref={submitButtonRef}
            type="submit"
            hidden
          />
        </Form>
      </div>
    </Modal>
  );
};

export default CreateLeadModal;
