import React, { useEffect, useState } from "react";
import styles from "./AllProjects.module.css";
import { Button, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import DefaultLayout from "../layout/DefaultLayout";
import Loader from "../components/Loader";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Permissions } from "../enum/Permissions";
import { fetchAllLeads, fetchMyLeads } from "../api/LeadAPI";
import useAllLead from "../hooks/useAllLead";
import AllLeadsTable from "../components/AllLeadsTable";
import { LeadListDetails } from "../types/Lead";
import CreateLeadModal from "../components/CreateLeadModal";
import UpdateLeadModal from "../components/UpdateLeadModal";

enum LeadFilterType {
  MY_LEADS = 0,
  ALL_LEADS = 1,
}

const filterOptions = [
  { label: "My Leads", value: LeadFilterType.MY_LEADS },
  { label: "All Leads", value: LeadFilterType.ALL_LEADS },
];

const AllLeads: React.FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const [shouldOpenDialog, setShouldOpenDialog] = useState(false);
  const [leads, setLeads] = useState<Array<LeadListDetails>>([]);
  const [permissions, setPermissions] = React.useState<Array<string>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [leadFilterType, setLeadFilterType] = React.useState<LeadFilterType>(
    LeadFilterType.MY_LEADS
  );
  const { onOpenEditModal, editModalRef } = useAllLead();

  const onOpenModal = () => {
    setShouldOpenDialog(true);
  };

  const onCloseModal = () => {
    setShouldOpenDialog(false);
  };

  const fetchLeadsData = () => {
    if (leadFilterType === LeadFilterType.MY_LEADS) {
      fetchMyLeads().then((response) => {
        setLeads(response.data.leads);
        setLoading(false);
      });
    } else {
      fetchAllLeads().then((response) => {
        setLeads(response.data.leads);
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    PermissionHelper.getPermissions().then((response) => {
      setPermissions(response);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchLeadsData();
  }, [leadFilterType]);

  return (
    <DefaultLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page_container}>
          <div>
            <Title level={4}>{t("Leads")}</Title>
          </div>

          <div className={styles.button_container}>
            {PermissionHelper.hasPermission(
              Permissions.LEAD_CREATE,
              permissions
            ) && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onOpenModal}
              >
                {t("Create Lead")}
              </Button>
            )}
            <Select
              className={styles.input}
              value={filterOptions[leadFilterType].value}
              onChange={(v: LeadFilterType) => setLeadFilterType(v)}
              options={filterOptions}
            />
          </div>

          <div>
            <AllLeadsTable
              permissions={permissions}
              leads={leads}
              loading={loading}
              onOpenEditModal={onOpenEditModal}
            />
          </div>

          <CreateLeadModal
            showModal={shouldOpenDialog}
            onRefetchData={fetchLeadsData}
            onClose={onCloseModal}
          />

          <UpdateLeadModal ref={editModalRef} onRefetchData={fetchLeadsData} />
        </div>
      )}
    </DefaultLayout>
  );
};

export default AllLeads;
