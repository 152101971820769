import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useLocation, useNavigate } from "react-router-dom";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { Permissions } from "../enum/Permissions";

const useDefaultLayout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
  
    const [permissions, setPermissions] = React.useState<Array<string>>([]);
    const [siderItems, setSiderItems] = React.useState<NonNullable<ItemType>[]>([
        {
            label: t("My Incentives"),
            key: "my-incentives",
            children: [
                {
                    label: t("Quarterly Bonus"),
                    key: "quarterly-bonus",
                    onClick: () => navigate("/"),
                },
                {
                    label: t("About SANDP1T Incentives"),
                    key: "about-incentives",
                    onClick: () => navigate("/about-incentives"),
                },
            ],
        }
    ]);

    // Extract the base path from the current location
    const basePath = location.pathname.split("/")[1];

    // Set the selected key to the base path
    const selectedKey = basePath === "" ? "quarterly-bonus" : `${basePath}`;

    // const tenantId = await ;

    useEffect(() => {
        PermissionHelper.getPermissions()
            .then((response) => {
                setPermissions(response);
            })
            .catch(() => {
                navigate('/error/oops')
            })
    }, [navigate]);

    useEffect(() => {
        if (!permissions.length) return;
        if (!siderItems.find(item => item.key === "projects") && PermissionHelper.hasPermission(Permissions.LIST_PROJECT, permissions)) {
            setSiderItems((prev) => [
                ...prev,
                {
                    label: "All Projects",
                    key: "projects",
                    onClick: () => navigate("/projects"),
                },
            ]);
        }
        if (!siderItems.find(item => item.key === "contributors") && PermissionHelper.hasPermission(Permissions.CONTRIBUTOR_LIST, permissions)) {
            setSiderItems((prev) => [
                ...prev,
                {
                    label: "All Contributors",
                    key: "contributors",
                    onClick: () => navigate("/contributors"),
                },
            ]);
        }
        if (!siderItems.find(item => item.key === "my-prut") && PermissionHelper.hasPermission(Permissions.PRUT_LIST, permissions)) {
            setSiderItems((prev) => [
                ...prev,
                {
                    label: "My PRUT",
                    key: "my-prut",
                    onClick: () => navigate("/my-prut"),
                },
            ]);
        }
        if (!siderItems.find(item => item.key === "leads") && PermissionHelper.hasPermission(Permissions.LEAD_LIST, permissions)) {
          setSiderItems((prev) => [
              ...prev,
              {
                  label: "Leads",
                  key: "leads",
                  onClick: () => navigate("/leads"),
              },
          ]);
        }
    }, [permissions, siderItems, navigate]);

    return { selectedKey, siderItems };
}

export default useDefaultLayout;
