export enum Permissions {
    LIST_PROJECT = "project:list",
    EDIT_PROJECT = "project:edit",
    EDIT_ARCHIVED_PROJECT = "project:editarchived",
    ARCHIVE_PROJECT = "project:archive",
    CREATE_PROJECT = "project:create",
    PRUT_LIST = "prut:list",
    SYNC_CONTRIBUTOR = "contributor:sync",
    CREATE_BONUS = "incentives:create",
    PRUT_EXPORT = "prut:export",
    CONTRIBUTOR_ADD = "contributor:add",
    CONTRIBUTOR_LIST = "contributor:list",
    LEAD_LIST = "lead:list",
    LEAD_CREATE = "lead:create",
    LEAD_EDIT = "lead:edit",
    LEAD_EDIT_APPROVAL = "lead:editapproval",
    LEAD_EDIT_PROGRESS = "lead:editprogress",
    LEAD_ADD_MEMBER = "lead:addmember",
    LEAD_EDIT_MEMBER_ROLE = "lead:editmemberrole",
    LEAD_DELETE_MEMBER = "lead:deletemember",
    LEAD_TRANSFER_OWNERSHIP = "lead:transferownership",

}
