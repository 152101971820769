import React from 'react'
import { ButtonProps, Divider, Modal, notification, Select, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseOutlined } from '@ant-design/icons';
import { createMember, fetchUsers } from '../api/LeadAPI';
import { LeadGetDetails, UserLeadMapping, UserOptions } from '../types/Lead';

import styles from './AddUserLeadModal.module.css';
import Loader from "./Loader";
import UpdateCommissionRole from './UpdateCommissionRole';

type AddMembersProps = {
  onRefetchData: VoidFunction,
  onCloseModal: VoidFunction,
  openModal: boolean,
  lead: LeadGetDetails
};

const AddMembers = ({
  lead,
  openModal,
  onRefetchData,
  onCloseModal,
}: AddMembersProps) => {

  const { Option } = Select;
  const { Title, Text } = Typography;

  const [users, setUsers] = useState<UserOptions[]>([]);
  const [tempNewMember, setNewMember] = useState<UserLeadMapping[]>([])
  const [tempExistingMembers, setExistingMembers] = useState<UserLeadMapping[]>([])
  const [loading, setLoading] = useState<boolean>()
  const [showComissionContent, setShowComissionContent] = useState(false)
  const [error, setError] = useState<string>('');
  const [comissionError, setCommissionError] = useState<string>('');
  
  const { t } = useTranslation();

  const fetchUsersOptions = () => {
    setLoading(true);
    fetchUsers().then((response) => {
      const tempUsers = response.data.map(( child: UserOptions) => ({
        ... child,
        upn: `${child.upn}${child.isActive ? '' : ' (inactive)' }`
      }))
      setUsers(tempUsers);
      setLoading(false);
    });
  };

  const onRemoveMember = (index: number) => {
	  const temp = [ ... tempNewMember ]
	  temp.splice(index, 1)

		setNewMember(temp)	  
	}

  useEffect(() => {
    const tempSum = [... tempExistingMembers, ... tempNewMember].reduce((sum, row) => (row.commissionShare ? sum + row.commissionShare : sum), 0);

    if (tempSum !== 100) {
      setCommissionError('The changes cannot be saved as the Total commission does not amount to 100%. Please adjust accordingly before attempting to save it.')
    } else {
      setCommissionError('')
    }
  }, [
    tempNewMember,
    tempExistingMembers
  ])

  useEffect(() => {
    const existingUpns = new Set(lead.members.map((user) => user.userId));
    const duplicateMessages: string[] = [];
  
    tempNewMember.forEach((user) => {
      if (existingUpns.has(user.userId)) {
        duplicateMessages.push(`${user.upn} is an existing member in this lead.`);
      }
    });
  
    if (duplicateMessages.length > 0) {
      setError(duplicateMessages.join(' '));
    } else {
      setError('');
    }
  }, [tempNewMember, lead.members])

  const onModalOk =  async () => {
    if(!showComissionContent){
      return setShowComissionContent(true)
    }

    await createMember({
      id: lead.id,
      existingMembers: tempExistingMembers,
      newMembers: tempNewMember
    }).then(() => {
      onRefetchData()
      notification.open({
        type: "success",
        placement: "topRight",
        top: 84,
        message: t("New member(s) added!"),
        description: `Member(s) have been successfully added to ${lead.title}.`,
        className: styles.alert_success,
      });
      onCloseModal();
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: styles.alert_error,
        });
      });
  }

  const onModalCancel = (e?: React.MouseEvent<HTMLElement>): void => {
    if (e) {
      const target = e.target as HTMLElement;
      if (target.tagName === 'svg' || target.className === 'ant-modal-close-x' ) {
        return onCloseModal()
      } else {
        if (!showComissionContent ){
          return onCloseModal()
        }
        setShowComissionContent(false)
      }
    }
  }

  const handleSelect = (email: string | null) => {
    const user = users.find((u) => u.upn === email);
    const checkExisting = tempNewMember.findIndex((u) => u.upn === email);
    
    if (!user ) return;
    
    if( checkExisting > -1 ) return;

    setNewMember([
      ...tempNewMember, 
      {
        userId: user.id,
        leadId: lead.id,
        role: 1,
        upn: user.upn,
        commissionShare: 0
      }
    ]);
  };

  useEffect(() => {
    if(openModal){
      fetchUsersOptions()
    } else {
      setNewMember([])
      setError('')
      setCommissionError('')
      setShowComissionContent(false)
    }
  }, [openModal])

  useEffect(() => {
    openModal && 
    setExistingMembers(lead.members)
  }, [openModal, lead])

  return (
    <Modal
      width={1000}
      title={<Title level={5}>
        {t('Add Member')}
      </Title>}
      data-testid="add-lead-modal"
      open={openModal}
      onOk={() => onModalOk()}
      onCancel={onModalCancel}
      cancelText={ !showComissionContent ? 'Cancel' : 'Back'}
      okText={ !showComissionContent ? t('Next') : t('Save Changes')}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: !showComissionContent 
            ? tempNewMember.length < 1 || error
            : comissionError,
        } as ButtonProps
      }
      closeIcon={<CloseOutlined data-testid='close-modal-button'/>}
      destroyOnClose
    >
      {
        loading && (
        <Loader className={styles.loading_container} />
        )
      }
      {
        !loading && (
          <>
            {
              showComissionContent && (
                <>
                  <UpdateCommissionRole
                    lead={lead}
                    hideRoleColumn={false}
                    tempNewMember={tempNewMember}
                    setNewMember={(val: UserLeadMapping[]) => setNewMember(val)}
                    tempExistingMembers={tempExistingMembers}
                    setExistingMembers={(val: UserLeadMapping[]) => setExistingMembers(val)}
                    setMadeChanges={() => {}}
                    comissionError={comissionError}
                  />
                </>
              )
            }
            {
              !showComissionContent && (
                <>
                  <Text>
                    {t('You can up to 5 members')}
                  </Text>
                  <Divider/>
                  <Select
                    showSearch
                    data-testid="member-selector"
                    notFoundContent={t("No results found")}
                    disabled={tempNewMember.length > 4}
                    placeholder="Enter Contributor's Email"
                    value={null}
                    className={styles.w_100}
                    onSelect={handleSelect}
                    optionFilterProp="children"
                  >
                    {users.map((user) => (
                      <Option key={user.id} value={user.upn}>
                        {user.upn}
                      </Option>
                    ))}
                  </Select>
                  {error && <Text className={styles.error_text}>{(error)}</Text>}
                  <div className={styles.member_badge_parent_cont} data-testid="selected-member">
                    {tempNewMember.map((user, userIndex) => (
                      <Tag 
                        data-testid="remove-selected-member"
                        className={styles.member_badge_cont} 
                        key={user.upn}
                        closable
                        onClose={() => onRemoveMember(userIndex)}
                      >
                        {user.upn}
                      </Tag>
                    ))}
                  </div>
                </>   
              )
            }
          </>
        )
      }
    </Modal>
  );
};

export default AddMembers;
