import { Button, Card, Typography } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchLead } from "../api/LeadAPI";
import AddUserLeadModal from '../components/AddUserLeadModal';
import Breadcrumb, { BreadcrumbItem } from "../components/BreadCrumb";
import LeadApprovalStatusCard from "../components/LeadApprovalStatusCard";
import LeadDetailsCard from "../components/LeadDetailsCard";
import LeadProgressCard from "../components/LeadProgressCard";
import Loader from "../components/Loader";
import UpdateCommissionModal from '../components/UpdateCommissionModal';
import UpdateLeadApprovalStatusModal from "../components/UpdateLeadApprovalStatusModal";
import UpdateLeadModal from "../components/UpdateLeadModal";
import UpdateLeadProgressModal from "../components/UpdateLeadProgressModal";
import UpdateRoleModal from '../components/UpdateRoleModal';
import DeleteConsultantModal from '../components/DeleteConsultantModal';
import DeleteMembersModal from '../components/DeleteMemberModal';
import UserLeadTable from '../components/UserLeadTable';
import TransferOwnershipModal from '../components/TransferOwnership'
import { LeadApprovalStatus } from "../enum/LeadApprovalStatus";
import { LeadStatus } from "../enum/LeadStatus";
import { LeadType } from "../enum/LeadType";
import { Permissions } from "../enum/Permissions";
import { PermissionHelper } from "../helpers/PermissionHelper";
import { useLeadDetail } from "../hooks";
import DefaultLayout from "../layout/DefaultLayout";
import { LeadHelper } from "../helpers/LeadHelper";
import { LeadGetDetails, UserLeadMapping } from "../types/Lead";

import styles from "./LeadDetails.module.css";

const LeadDetails: React.FC = () => {
  const { id } = useParams();
  const { Title, Text } = Typography;
  const [permissions, setPermissions] = React.useState<Array<string>>([]);
  const [lead, setLead] = React.useState<LeadGetDetails>({
    id: "",
    owner: { id: "", name: "" },
    title: "",
    type: LeadType.OPERATIONS_AND_SUPPORT,
    contractRef: "",
    totalContractValue: "",
    totalCommissionValue: "",
    description: "",
    status: LeadStatus.PENDING_SUBMISSION,
    approvalStatus: LeadApprovalStatus.AWAITING_REVIEW,
    estimatedPaymentMilestone: "",
    paymentDate: "",
    payoutMonth: "",
    isOwner: false,
    members: []
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  
  const [selectedDeleteConsultant, setDeleteConsultant] = React.useState<UserLeadMapping | null>(null);
  const [selectedDeleteMember, setDeleteMember] = React.useState<UserLeadMapping | null>(null);
  const [selectedEditRole, setEditRole] = React.useState<string>('');
  const [showAddMember, setShowAddMember] = React.useState<boolean>(false);
  const [showEditCommission, setShowEditComission] = React.useState<boolean>(false);
  const [showTransfer, setShowTransfer] = React.useState<boolean>(false);

  const hasPermission = 
  (PermissionHelper.hasPermission(Permissions.LEAD_EDIT_MEMBER_ROLE,permissions)
  && PermissionHelper.hasPermission(Permissions.LEAD_DELETE_MEMBER,permissions)) || lead.isOwner;

  const ownerUser = useMemo(() => {
    const tempOwner = lead.members.find(child => (
      child.userId === lead.owner.id
    ))

    return tempOwner
  }, [lead])

  const {
    editModalRef,
    editApprovalStatusModalRef,
    editProgressModalRef,
    reloadTable,
    setReloadTable,
    onOpenEditLead,
    onOpenEditLeadApprovalStatus,
    onOpenEditLeadProgress,
  } = useLeadDetail({ lead });

  const breadcrumbItems: BreadcrumbItem[] = [
    { path: "/leads", name: "Leads" },
    { path: "", name: lead.title },
  ];

  const fetchData = useCallback(() => {
    Promise.all([
      fetchLead(id as string).then((response) => {
        let tempSorted = [...response.data.lead.members]
        tempSorted = tempSorted.map(sortChild => ({
          ... sortChild,
          upn: `${sortChild.upn} ${sortChild.isActive ? '' : '(inactive)' }`
        }))

        tempSorted = tempSorted.sort((a, b) => {
          if (a.role === 0 && b.role !== 0) return 1;
          if (a.role !== 0 && b.role === 0) return -1;
          return b.commissionShare - a.commissionShare;
        });
        
        setLead({
          ... response.data.lead,
          members: tempSorted
        });
        setLoading(false);
      }),
    ]).finally(() => {
      setReloadTable(false);
    });
  }, [id, setReloadTable]);

  useEffect(() => {
    PermissionHelper.getPermissions().then((response) => {
      setPermissions(response);
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    reloadTable && fetchData();
  }, [reloadTable, fetchData]);

  return (
    <DefaultLayout>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.page_container}>
          <div className={styles.page_header}>
            <Breadcrumb items={breadcrumbItems} />
            <div className={styles.page_header_title_container}>
              <Title level={4} className={styles.page_header_title}>
                {lead.title}
              </Title>
            </div>
          </div>

          <LeadDetailsCard
            hasEditPermission={PermissionHelper.hasPermission(
              Permissions.LEAD_EDIT,
              permissions
            )}
            lead={lead}
            onOpenEditModal={onOpenEditLead}
          />

          <LeadApprovalStatusCard
            hasEditPermission={PermissionHelper.hasPermission(
              Permissions.LEAD_EDIT_APPROVAL,
              permissions
            )}
            leadApprovalStatus={lead.approvalStatus}
            onOpenEditModal={onOpenEditLeadApprovalStatus}
          />

          <LeadProgressCard
            hasEditPermission={PermissionHelper.hasPermission(
              Permissions.LEAD_EDIT_PROGRESS,
              permissions
            )}
            lead={lead}
            onOpenEditModal={onOpenEditLeadProgress}
          />

          <Card>
            <div className={styles.lead_owner_container}>
              <Text className={styles.lead_owner_header}>Lead Owner</Text>
              <Text className={styles.lead_owner_value}>{`${ownerUser?.upn}`}</Text>
              {
                hasPermission && (
                  <Button 
                    type="link" 
                    onClick={() => setShowTransfer(true)}
                    disabled={
                      !(
                        PermissionHelper.hasPermission(
                          Permissions.LEAD_EDIT_PROGRESS,
                          permissions
                        ) ||
                        (lead.isOwner &&
                          LeadHelper.isLeadStatusEditable(
                            lead.status,
                            lead.approvalStatus
                          ))
                      ) || lead.members.length < 2
                    } 
                  >
                    Transfer Ownership
                  </Button>
                )
              }
            </div>
          </Card>

          <UserLeadTable
            lead={lead}
            hasEditPermission={PermissionHelper.hasPermission(
              Permissions.LEAD_EDIT_PROGRESS,
              permissions
            )}
            onRefetchData={fetchData}
            permissions={permissions}
            setShowAddMember={(val: boolean) => setShowAddMember(val)}
            setShowEditComission={(val: boolean) => setShowEditComission(val)}
            setEditRole={(val: string) => setEditRole(val)}
            setDeleteConsultant={(val: UserLeadMapping | null) => setDeleteConsultant(val)}
            setDeleteMember={(val: UserLeadMapping | null) => setDeleteMember(val)}
          />
          
          <UpdateLeadModal ref={editModalRef} onRefetchData={fetchData} />

          <UpdateLeadApprovalStatusModal
            ref={editApprovalStatusModalRef}
            onRefetchData={fetchData}
          />

          <UpdateLeadProgressModal
            ref={editProgressModalRef}
            onRefetchData={fetchData}
          />

          <AddUserLeadModal
            lead={lead}
            openModal={showAddMember}
            onRefetchData={fetchData}
            onCloseModal={() => setShowAddMember(false)}
          />

          <UpdateCommissionModal
            lead={lead}
            openModal={showEditCommission}
            onRefetchData={fetchData}
            onCloseModal={() => setShowEditComission(false)}
          />
          
          <UpdateRoleModal
            lead={lead}
            selectedEditRole={selectedEditRole}
            openModal={selectedEditRole !== ''}
            onRefetchData={fetchData}
            onCloseModal={() => setEditRole('')}
          />

          <DeleteConsultantModal
            lead={lead}
            selectedDeleteConsultant={selectedDeleteConsultant}
            openModal={selectedDeleteConsultant !== null}
            onRefetchData={fetchData}
            onCloseModal={() => setDeleteConsultant(null)}
          />
          
          <DeleteMembersModal
            lead={lead}
            selectedDeleteMember={selectedDeleteMember}
            openModal={selectedDeleteMember !== null}
            onRefetchData={fetchData}
            onCloseModal={() => setDeleteMember(null)}
          />

          <TransferOwnershipModal
            lead={lead}
            onRefetchData={fetchData}
            openModal={showTransfer}
            onCloseModal={() => setShowTransfer(false)}
          />
        </div>
      )}
    </DefaultLayout>
  );
};

export default LeadDetails;
