import { Dispatch, SetStateAction, useCallback, useRef } from "react";

type Props = {
  setButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

const useTransferOwnership = ({
  setButtonDisabled,
}: Props) => {
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const trackingChangesRef = useRef<{ [key: string]: boolean }>({});


  const onModalOk = () => {
    submitButtonRef.current?.click();
  };

  const clearTrackingChanges = useCallback(() => {
    trackingChangesRef.current = {};
    setButtonDisabled(true);
  }, [setButtonDisabled]);

  return { submitButtonRef, onModalOk, clearTrackingChanges };
};

export default useTransferOwnership;
