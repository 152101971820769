import { ButtonProps, Form, Input, Modal, notification, Select, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { editRole } from '../api/LeadAPI';
import { LeadGetDetails, UserLeadMapping } from '../types/Lead';

import styles from './AddUserLeadModal.module.css';

import { CloseOutlined } from '@ant-design/icons';
import UpdateCommissionRole from './UpdateCommissionRole';

type UpdateRoleProps = {
  onRefetchData: VoidFunction,
  onCloseModal: VoidFunction,
  openModal: boolean,
  selectedEditRole: string,
  lead: LeadGetDetails
};

const UpdateRole = ({
  lead,
  selectedEditRole,
  openModal,
  onRefetchData,
  onCloseModal,
}: UpdateRoleProps) => {

  const { Title } = Typography;
  const { Option } = Select;

  const [tempExistingMembers, setExistingMembers] = useState<UserLeadMapping[]>([])
  const [tempUser, setTempUser] = useState<UserLeadMapping | null>(null)
  const [showComissionContent, setShowComissionContent] = useState(false)
  const [haveMadeRoleChanges, setMadeRoleChanges] = useState(false)
  const [error, setError] = useState<string>('');
  const [comissionError, setCommissionError] = useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    const tempSum = tempExistingMembers.reduce((sum, row) => (row.commissionShare ? sum + row.commissionShare : sum), 0);

    if (tempSum !== 100) {
      setCommissionError('The changes cannot be saved as the Total commission does not amount to 100%. Please adjust accordingly before attempting to save it.')
    } else {
      setCommissionError('')
    }
  }, [
    tempExistingMembers
  ])

  useEffect(() => {
    const tempFind = tempExistingMembers.find( memberChild => {
      return memberChild.id === selectedEditRole
    })

    if(tempFind && tempUser){
      return setMadeRoleChanges( tempFind.role !== tempUser.role )
    }

    setMadeRoleChanges(false)
  }, [lead, tempUser])

  const onHandleRoleChange = (val: number) => {
    setMadeRoleChanges(true)
    setTempUser({
      ...tempUser!,
      role: val,
      commissionShare: val === 0 ? 0 : tempUser?.commissionShare || 0
    } as UserLeadMapping);
  }

  const onModalOk =  async () => {
    if(!showComissionContent){
      const tempMembers = [ ... tempExistingMembers ]
      const tempFind = tempExistingMembers.findIndex( memberChild => {
        return memberChild.id === selectedEditRole
      })

      if (tempFind > -1 && tempUser) {
        tempMembers[tempFind] = tempUser
        setExistingMembers(tempMembers)
      }

      return setShowComissionContent(true)
    }

    await editRole({
      id: lead.id,
      members: tempExistingMembers
    }).then(() => {
      onRefetchData()
      notification.open({
        type: "success",
        placement: "topRight",
        top: 84,
        message: t("Role details saved!"),
        description: `The role details are now updated.`,
        className: styles.alert_success,
      });
      onCloseModal();
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: styles.alert_error,
        });
      });
  }

  const onModalCancel = (e?: React.MouseEvent<HTMLElement>): void => {
    if (e) {
      const target = e.target as HTMLElement;
      if (target.tagName === 'svg' || target.className === 'ant-modal-close-x' ) {
        return onCloseModal()
      } else {
        if (!showComissionContent ){
          return onCloseModal()
        }
        setShowComissionContent(false)
      }
    }
  }

  useEffect(() => {
    if(!openModal){
      setError('')
      setShowComissionContent(false)
      setMadeRoleChanges(false)
    }
  }, [openModal])

  useEffect(() => {
    if(openModal && selectedEditRole) {
      const tempFind = lead.members.find( memberChild => {
        return memberChild.id === selectedEditRole
      })

      tempFind &&
      setTempUser(tempFind)

      setExistingMembers(lead.members)
    }
  }, [lead, openModal, selectedEditRole])

  return (
    <Modal
      width={1000}
      title={<Title level={5}>
        {t('Edit Role')}
      </Title>}
      data-testid="edit-role-modal"
      open={openModal}
      onOk={() => onModalOk()}
      onCancel={onModalCancel}
      cancelText={'Cancel'}
      okText={ !showComissionContent ? t('Next') : t('Save Changes')}
      closeIcon={<CloseOutlined data-testid='close-modal-button'/>}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: !showComissionContent 
            ? !haveMadeRoleChanges || error
            : comissionError,
        } as ButtonProps
      }
      destroyOnClose
    >
      <>
        {
          showComissionContent && (
            <UpdateCommissionRole
              lead={lead}
              hideRoleColumn={true}
              tempExistingMembers={tempExistingMembers}
              setExistingMembers={(val: UserLeadMapping[]) => setExistingMembers(val)}
              setMadeChanges={() => {}}
              comissionError={comissionError}
            />
          )
        }
        {
          !showComissionContent && (
            <>
              <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 20 }}
                labelWrap
              >
                <Form.Item
                  label={t("Member's Name")}
                  >
                  <Input
                    data-testid="member-name-input"
                    disabled={true}
                    value={tempUser?.upn}
                  />
                </Form.Item>

                <Form.Item
                  label={t("Role")}
                  required
                > 
                  <Select
                    data-testid="role-selector"
                    value={tempUser?.role}
                    onChange={(val) => onHandleRoleChange(+val)}
                  >
                    <Option value={0}>Consultant</Option>
                    <Option value={1}>Member</Option>
                  </Select>
                </Form.Item>
              </Form>
            </>   
          )
        }
      </>
    </Modal>
  );
};

export default UpdateRole;
