import { ButtonProps, Divider, Form, Modal, Select, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LeadGetDetails, TransferOwnershipField, UserLeadMapping } from "../types/Lead";

import { transferOwner } from "../api/LeadAPI";
import useTransferOwnership from "../hooks/useTransferOwnership";

import styles from "./TransferOwnership.module.css";

type AddMembersProps = {
  onRefetchData: VoidFunction;
  onCloseModal: VoidFunction;
  openModal: boolean;
  lead: LeadGetDetails;
};

const TransferOwner = ({ lead, openModal, onRefetchData, onCloseModal }: AddMembersProps) => {
  const { Option } = Select;
  const { Title, Text } = Typography;
  const [form] = Form.useForm<TransferOwnershipField>();
  const [membersOptions, setMembersOptions] = useState<UserLeadMapping[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { t } = useTranslation();

  const { submitButtonRef, onModalOk, clearTrackingChanges } = useTransferOwnership({
    setButtonDisabled
  });

  useEffect(() => {
    const tempOptions = lead.members.filter((member) => member.userId !== lead.owner.id && member.role === 1);
    setMembersOptions(tempOptions);
  }, [lead]);

  const onFieldChange = () => {
    setButtonDisabled(form.getFieldsError().some((field) => field.errors.length > 0));
  };

  const onSubmit = async (val: TransferOwnershipField) => {
    await transferOwner({
      id: lead.id,
      ownerId: val.userId
    })
      .then(() => {
        onRefetchData();
        const tempFind = lead.members.find((member) => member.userId === val.userId);

        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Lead ownership Transferred!"),
          description: `The editing rights for ${lead.title} is successfully transferred to ${tempFind?.upn}`,
          className: styles.alert_success
        });
        onCloseModal();
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: styles.alert_error
        });
      })
      .finally(() => {
        form.resetFields();
      });
  };

  const onModalCancel = () => {
    form.resetFields();
    clearTrackingChanges();
    return onCloseModal();
  };

  return (
    <Modal
      width={800}
      title={<Title level={5}>{t("Transfer Ownership")}</Title>}
      data-testid="transfer-owner-modal"
      open={openModal}
      onOk={() => onModalOk()}
      onCancel={() => onModalCancel()}
      cancelText={"Cancel"}
      okText={t("Save Changes")}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: buttonDisabled
        } as ButtonProps
      }
      destroyOnClose
    >
      <Text>{t("Transfer Ownership Msg")}</Text>
      <Divider />
      <Form form={form} onFinish={onSubmit} onFieldsChange={onFieldChange} labelWrap>
        <Form.Item
          name="userId"
          className={styles.w_100}
          rules={[{ required: true, message: "This is a required field." }]}
        >
          <Select
            showSearch
            data-testid="contributor-selector"
            className={styles.w_100}
            placeholder="Select Contributor's Email"
            optionFilterProp="children"
          >
            {membersOptions.map((user) => (
              <Option key={user.userId} value={user.userId}>
                {user.upn}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <input data-testid="submit-form" ref={submitButtonRef} type="submit" hidden />
      </Form>
    </Modal>
  );
};

export default TransferOwner;
