import { LeadApprovalStatus } from "../enum/LeadApprovalStatus";
import { LeadStatus } from "../enum/LeadStatus";
import { LeadType } from "../enum/LeadType";
import { DataTableHelper } from "./DataTableHelper"

const statusSorter = (a: number, b: number) => {
    return DataTableHelper.sorter(a, b);
}

const typeSorter = (a: number, b: number) => {
  return DataTableHelper.sorter(a, b);
}

const getStatusColor = (status: number) => {
  let statusColor: string;
  switch (status) {
      case LeadStatus.PENDING_SUBMISSION:
          statusColor = 'gold';
          break;
      case LeadStatus.BID_SUBMITTED:
          statusColor = 'blue';
          break;
      case LeadStatus.BID_SUCCESSFUL:
          statusColor = 'green';
          break;
      case LeadStatus.BID_FAILED:
          statusColor = 'red';
          break;
      case LeadStatus.INACTIVE:
          statusColor = 'default';
          break;
      default:
          statusColor = 'default';
  }
  return statusColor;
}

const getStatusTagName = (status: number) => {
  let statusName: string;
  switch (status) {
      case LeadStatus.PENDING_SUBMISSION:
          statusName = 'Pending Submission';
          break;
      case LeadStatus.BID_SUBMITTED:
          statusName = 'Bid Submitted';
          break;
      case LeadStatus.BID_SUCCESSFUL:
          statusName = 'Bid Successful';
          break;
      case LeadStatus.BID_FAILED:
          statusName = 'Bid Failed';
          break;
      case LeadStatus.INACTIVE:
          statusName = 'Inactive';
          break;
      default:
          statusName = '-';
  }
  return statusName;
}

const getApprovalStatusTagName = (approvalStatus: number) => {
  let approvalStatusName: string;
  switch (approvalStatus) {
      case LeadApprovalStatus.AWAITING_REVIEW:
          approvalStatusName = 'Awaiting Review';
          break;
      case LeadApprovalStatus.CONDITIONAL_APPROVAL:
          approvalStatusName = 'Conditional Approval';
          break;
      case LeadApprovalStatus.FINAL_APPROVAL:
          approvalStatusName = 'Final Approval';
          break;
      default:
          approvalStatusName = '-';
  }
  return approvalStatusName;
}


const getStatusFilterOptions = () => {
  return [
      { value: LeadStatus.BID_FAILED, text: getStatusTagName(LeadStatus.BID_FAILED) },
      { value: LeadStatus.BID_SUBMITTED, text: getStatusTagName(LeadStatus.BID_SUBMITTED) },
      { value: LeadStatus.BID_SUCCESSFUL, text: getStatusTagName(LeadStatus.BID_SUCCESSFUL) },
      { value: LeadStatus.INACTIVE, text: getStatusTagName(LeadStatus.INACTIVE) },
      { value: LeadStatus.PENDING_SUBMISSION, text: getStatusTagName(LeadStatus.PENDING_SUBMISSION) },
  ];
}


const getTypeTagName = (type: number) => {
  let typeName: string;
  switch (type) {
      case LeadType.IMPLEMENTATION_OR_CONSULTANCY:
          typeName = 'Implementation/Consultancy';
          break;
      case LeadType.OPERATIONS_AND_SUPPORT:
          typeName = 'Operations & Support';
          break;
      default:
          typeName = '-';
  }
  return typeName;
}

const isLeadStatusEditable = (status: number, approvalStatus: number) => {
  return !(
      status === LeadStatus.INACTIVE ||
      status === LeadStatus.BID_FAILED ||
      approvalStatus === LeadApprovalStatus.CONDITIONAL_APPROVAL ||
      approvalStatus === LeadApprovalStatus.FINAL_APPROVAL
  )
}

export const LeadHelper = {
    statusSorter,
    typeSorter,
    getStatusColor,
    getStatusTagName,
    getTypeTagName,
    getStatusFilterOptions,
    getApprovalStatusTagName,
    isLeadStatusEditable,
} 