import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  Alert,
  ModalProps as AntDModalProps,
  ButtonProps,
  Form,
  notification,
  Select,
  Typography,
} from "antd";
import Modal from "./Modal";
import styles from "./UpdateLeadApprovalStatusModal.module.css";
import { useTranslation } from "react-i18next";
import useUpdateLeadApprovalStatusModal from "../hooks/useUpdateLeadApprovalStatusModal";
import {
  EditLeadApprovalStatusFormField,
  UpdateLeadApprovalStatusModalAction,
} from "../types/Lead";
import { editLeadApprovalStatus } from "../api/LeadAPI";
import { LeadApprovalStatus } from "../enum/LeadApprovalStatus";

interface UpdateLeadApprovalStatusModalProps extends AntDModalProps {
  onRefetchData: VoidFunction;
}

const LEAD_APPROVAL_STATUSES = [
  { label: "Awaiting Review", value: LeadApprovalStatus.AWAITING_REVIEW },
  {
    label: "Conditional Approval",
    value: LeadApprovalStatus.CONDITIONAL_APPROVAL,
  },
  {
    label: "Final Approval",
    value: LeadApprovalStatus.FINAL_APPROVAL,
  },
];

export const UpdateLeadApprovalStatusModal = forwardRef<
  UpdateLeadApprovalStatusModalAction,
  UpdateLeadApprovalStatusModalProps
>(function UpdateLeadApprovalStatusModal({ onRefetchData }, ref) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm<EditLeadApprovalStatusFormField>();
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalInfo, setModalInfo] = useState<
    EditLeadApprovalStatusFormField & { title: string }
  >({
    id: "",
    title: "",
    approvalStatus: LeadApprovalStatus.AWAITING_REVIEW,
  });
  const { submitButtonRef, onModalOk, clearTrackingChanges } =
    useUpdateLeadApprovalStatusModal({
      form,
      modalInfo,
      setButtonDisabled,
    });

  const onFieldChange = () => {
    setButtonDisabled(
      form.getFieldsError().some((field) => field.errors.length > 0)
    );
  };

  const onSubmit = async (value: EditLeadApprovalStatusFormField) => {
    await editLeadApprovalStatus({ ...value, id: modalInfo.id })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Lead approval status saved!"),
          description: `The approval status for ${modalInfo.title} is now updated.`,
          className: styles.alert_success,
        });
        onRefetchData();
        close();
      })
      .catch((error) => {
        const { status } = error.response;
        switch (status) {
          default:
            notification.open({
              type: "error",
              placement: "topRight",
              top: 84,
              message: t("Action Failed"),
              description: t(
                "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
              ),
              className: styles.alert_error,
            });
            break;
        }
      })
      .finally(() => {
        clearTrackingChanges();
      });
  };

  const open = useCallback(
    (info: EditLeadApprovalStatusFormField & { title: string }) => {
      setModalInfo(info);
      form.setFields(
        Object.entries(info).map(([key, value]) => ({
          name: key,
          value: value,
        }))
      );
      setOpenModal(true);
    },
    [form]
  );

  const close = useCallback(() => {
    setOpenModal(false);
    clearTrackingChanges();
  }, [form]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal
      title={<Title level={5}>Edit Approval Status</Title>}
      data-testid="edit_lead_approval_status_modal"
      open={openModal}
      onOk={onModalOk}
      onCancel={close}
      okText={t("Save Changes")}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: buttonDisabled,
        } as ButtonProps
      }
      size={"medium"}
      destroyOnClose
    >
      <div className={styles.modal_content_container}>
        <Alert
          message="Once conditional or final approval is granted, all edits will be locked for the members."
          type="info"
          showIcon
        />
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSubmit}
          onFieldsChange={onFieldChange}
          labelWrap
        >
          <Form.Item
            label={t("Approval Status")}
            name="approvalStatus"
            rules={[{ required: true, message: "This is a required field." }]}
          >
            <Select
              className={styles.input}
              placeholder={t("Select Lead Approval Status")}
              options={LEAD_APPROVAL_STATUSES}
            />
          </Form.Item>

          <input
            data-testid="submit-form"
            ref={submitButtonRef}
            type="submit"
            hidden
          />
        </Form>
      </div>
    </Modal>
  );
});

export default UpdateLeadApprovalStatusModal;
