import React from "react";
import { Button, Card, Tooltip, Typography } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { LeadHelper } from "../helpers/LeadHelper";
import { useTranslation } from "react-i18next";
import { LeadStatus } from "../enum/LeadStatus";
import { LeadApprovalStatus } from "../enum/LeadApprovalStatus";
import { LeadType } from "../enum/LeadType";

import styles from "./LeadDetailsCard.module.css";

type LeadDetailsCardProps = {
  hasEditPermission: boolean;
  lead: {
    type: LeadType;
    isOwner: boolean;
    status: LeadStatus;
    approvalStatus: LeadApprovalStatus;
    contractRef: string;
    totalContractValue: string;
    totalCommissionValue: string;
    description: string;
  };
  onOpenEditModal: VoidFunction;
};

function LeadDetailsCard({
  hasEditPermission,
  lead,
  onOpenEditModal,
}: LeadDetailsCardProps) {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  return (
    <Card
      title={<Title level={5}>{t("Lead Details")}</Title>}
      extra={
        (hasEditPermission || lead.isOwner) && (
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={onOpenEditModal}
            disabled={
              !(
                hasEditPermission ||
                (lead.isOwner &&
                  LeadHelper.isLeadStatusEditable(
                    lead.status,
                    lead.approvalStatus
                  ))
              )
            }
          >
            Edit Lead Details
          </Button>
        )
      }
    >
      <div className={styles.card_body_container}>
        <div>
          <Text className={styles.body_title_header}>{t("Lead Type")}:</Text>
          <Text>{LeadHelper.getTypeTagName(lead.type)}</Text>
        </div>
        <div>
          <Text className={styles.body_title_header}>
            {t("Deal / Contract Reference")}:
          </Text>
          <Text>{lead.contractRef !== "" ? lead.contractRef : "-"}</Text>
        </div>
        <div>
          <Text className={styles.body_title_header}>
            {t("Total Contract Value")}:
          </Text>
          <Text>
            {lead.totalContractValue !== "" ? lead.totalContractValue : "-"}
          </Text>
        </div>
        <div>
          <div className={styles.body_title_header_with_tooltip}>
            <Text>{t("Total Commission Value")}:</Text>
            <Tooltip
              placement="right"
              title={`The maximum commission value allowed is $11,700`}
            >
              <InfoCircleOutlined className={styles.tooltip_icon} />
            </Tooltip>
          </div>
          <Text>
            {lead.totalCommissionValue !== "" ? lead.totalCommissionValue : "-"}
          </Text>
        </div>
        <div className={styles.body_description}>
          <Text className={styles.body_title_header}>{t("Description")}:</Text>
          <Text className={styles.body_description_value}>
            {lead.description && lead.description !== ""
              ? lead.description
              : "-"}
          </Text>
        </div>
      </div>
    </Card>
  );
}

export default LeadDetailsCard;
