import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { FormInstance } from "antd";
import { EditLeadProgressFormField } from "../types/Lead";
import { FieldData } from "rc-field-form/lib/interface";

type Props = {
  form: FormInstance<EditLeadProgressFormField>;
  modalInfo: EditLeadProgressFormField;
  setButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

const useUpdateLeadProgressModal = ({
  form,
  modalInfo,
  setButtonDisabled,
}: Props) => {
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const trackingChangesRef = useRef<{ [key: string]: boolean }>({});

  const onFieldsChange = (changedFields: FieldData[]) => {
    const { name, value } = changedFields[0];
    const changedFieldName = name;
    const changedValue = value;
    const changedKey =
      changedFieldName instanceof Array ? changedFieldName[0] : "";
    const lastValue = modalInfo[changedKey as keyof EditLeadProgressFormField];
    const trackingChanges = trackingChangesRef.current;
    trackingChanges[changedKey] = changedValue !== lastValue;
    trackingChangesRef.current = trackingChanges;
    const hasChanges = Object.keys(trackingChanges).some(
      (key) => trackingChanges[key]
    );
    const isError = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    setButtonDisabled(!hasChanges || isError);
  };

  const onModalOk = () => {
    submitButtonRef.current?.click();
  };

  const clearTrackingChanges = useCallback(() => {
    trackingChangesRef.current = {};
    setButtonDisabled(true);
  }, [setButtonDisabled]);

  return { submitButtonRef, onFieldsChange, onModalOk, clearTrackingChanges };
};

export default useUpdateLeadProgressModal;
