import { useRef, useState } from "react";
import {
  Lead,
  UpdateLeadApprovalStatusModalAction,
  UpdateLeadModalAction,
  UpdateLeadProgressModalAction,
} from "../types/Lead";

type Props = {
  lead: Lead;
};

const useLeadDetail = ({ lead }: Props) => {
  const editModalRef = useRef<UpdateLeadModalAction>(null);
  const editApprovalStatusModalRef = useRef<UpdateLeadApprovalStatusModalAction>(null);
  const editProgressModalRef = useRef<UpdateLeadProgressModalAction>(null);
  const [reloadTable, setReloadTable] = useState<boolean>(false);

  const onOpenEditLead = () => {
    editModalRef.current?.open(lead);
  };

  const onOpenEditLeadApprovalStatus = () => {
    editApprovalStatusModalRef.current?.open(lead);
  };

  const onOpenEditLeadProgress = () => {
    editProgressModalRef.current?.open(lead);
  };

  return {
    editModalRef,
    editApprovalStatusModalRef,
    editProgressModalRef,
    reloadTable,
    setReloadTable,
    onOpenEditLead,
    onOpenEditLeadApprovalStatus,
    onOpenEditLeadProgress,
  };
};

export default useLeadDetail;
