import React from "react";
import { Button, Card, Tooltip, Typography, Alert } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { LeadHelper } from "../helpers/LeadHelper";
import { useTranslation } from "react-i18next";
import { LeadApprovalStatus } from "../enum/LeadApprovalStatus";
import styles from "./LeadApprovalStatusCard.module.css";

type LeadApprovalStatusCardProps = {
  hasEditPermission: boolean;
  leadApprovalStatus: LeadApprovalStatus;
  onOpenEditModal: VoidFunction;
};

function LeadApprovalStatusCard({
  hasEditPermission,
  leadApprovalStatus,
  onOpenEditModal,
}: LeadApprovalStatusCardProps) {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <Card>
      <Alert
        message="Before submitting the bid, please complete all fields in lead details and contact the admin for conditional approval of the commission details. Once approved, no further edits will be allowed."
        type="info"
        showIcon
      />
      <div className={styles.lead_approval_status_container}>
        <div className={styles.body_title_header_with_tooltip}>
          <Text>{t("Approval Status from Admin")}:</Text>
          <Tooltip
            placement="right"
            title={
              "Conditional approval indicates that the commission amount may change due to ongoing client negotiations."
            }
          >
            <InfoCircleOutlined className={styles.tooltip_icon} />
          </Tooltip>
        </div>
        <Text className={styles.lead_approval_status_value}>
          {LeadHelper.getApprovalStatusTagName(leadApprovalStatus)}
        </Text>
        {hasEditPermission && (
          <Button
            className={styles.lead_approval_status_edit_btn}
            onClick={onOpenEditModal}
            type="text"
          >
            Edit
          </Button>
        )}
      </div>
    </Card>
  );
}

export default LeadApprovalStatusCard;
