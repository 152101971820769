import { useRef } from "react";
import { Lead, UpdateLeadModalAction } from "../types/Lead";

const useAllLead = () => {
  const editModalRef = useRef<UpdateLeadModalAction>(null);

  const onOpenEditModal = (info: Lead) => {
    editModalRef.current?.open(info);
  };

  return {
    editModalRef,
    onOpenEditModal,
  };
};

export default useAllLead;
