import { Button, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { DataTableHelper } from "../helpers/DataTableHelper";
import styles from './AllLeadsTable.module.css';
import { useNavigate } from "react-router-dom";
import { Lead, LeadListDetails } from "../types/Lead";
import { LeadHelper } from "../helpers/LeadHelper";

type AllLeadsTableProps = {
    permissions: Array<string>;
    leads: LeadListDetails[];
    loading: boolean;
    onOpenEditModal?: (lead: Lead) => void;
}

const AllLeadsTable: React.FC<AllLeadsTableProps> = ({ leads, loading, onOpenEditModal }) => {
    const { t } = useTranslation();
    const { Text } = Typography;
    const navigate = useNavigate();

    const columns: ColumnsType<LeadListDetails> = [
        {
            title: t('Lead Title'),
            dataIndex: 'title',
            width: '19.75rem',
            sorter: (a, b) => DataTableHelper.stringSorter(a.title, b.title),
            render: (text: string, record: Lead) => {
                return (
                    <Text className={styles.lead_title} onClick={() => navigate(`/leads/${record.id}`)}>{text}</Text>
                )
            }
        },
        {
            title: t('Lead Status'),
            dataIndex: 'status',
            width: '10rem',
            sorter: (a, b) => LeadHelper.statusSorter(a.status, b.status),
            defaultSortOrder: 'ascend',
            filters: LeadHelper.getStatusFilterOptions(),
            onFilter: (value, record) => DataTableHelper.filter(value, record.status),
            render: (status: number) => {
                return (
                    <Tag color={LeadHelper.getStatusColor(status)} key={status}>
                        {LeadHelper.getStatusTagName(status)}
                    </Tag>
                );
            }
        },
        {
            title: t('Lead Owner'),
            dataIndex: ['owner', 'name'],
            width: "11.5rem",
            sorter: (a, b) => DataTableHelper.stringSorter(a.owner?.name ?? '', b.owner?.name ?? ''),
            render: (name: string) => {
              return (
                <Text>{name}</Text>
              )
            }
        },
        {
          title: t('Lead Type'),
          dataIndex: 'type',
          width: '9.25rem',
          sorter: (a, b) =>LeadHelper.typeSorter(a.type, b.type),
          render: (type: number) => {
              return (
                  <Text>{LeadHelper.getTypeTagName(type)}</Text>
              )
          }
      },
    ];

    const actionsColumn = {
        title: t('Action(s)'),
        dataIndex: 'status',
        width: '9rem',
        render: (status: number, record: LeadListDetails) => {
            return (
                <div className={styles.actions_column}>
                    <Button
                        onClick={() => onOpenEditModal?.(record)}
                        className={styles.no_padding}
                        type="link"
                        disabled={!record.canEdit}
                    >
                        {t("Edit Lead Details")}
                    </Button>
                </div>
            );
        }
    };

    columns.push(actionsColumn);

    return (
      <div>
        <Table loading={loading} columns={columns} dataSource={leads} pagination={false} rowKey="id" />
      </div>
    );
}

export default AllLeadsTable;