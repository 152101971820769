import { useRef, useState } from "react";
import { FormInstance, notification } from "antd";
import type { TFunction } from "i18next";
import styles from "../components/CreateLeadModal.module.css";
import { LeadFormField } from "../types/Lead";
import { createLead } from "../api/LeadAPI";

type Props = {
  t: TFunction<"translation", undefined>;
  form: FormInstance<LeadFormField>;
  onRefetchData: VoidFunction;
  onClose: VoidFunction;
};

const useCreateLeadModal = ({ t, form, onRefetchData, onClose }: Props) => {
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onModalOk = () => {
    submitButtonRef.current?.click();
  };

  const onSubmit = async (value: LeadFormField) => {
    const { title, contractRef, description } = value;
    await createLead({
      ...value,
      title: title.trim(),
      contractRef: contractRef.trim(),
      ...(description ? { description: description.trim() } : {}),
    })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Lead created!"),
          description: `The new lead ${value.title} is now available on the Leads page.`,
          className: styles.alert_success,
        });
        onRefetchData();
        onCloseModal();
      })
      .catch((error) => {
        const { status, data } = error.response;
        if (status === 409) {
          form.setFields([
            {
              name: "title",
              errors: [data.message],
            },
          ]);
          setButtonDisabled(true);
        } else {
          notification.open({
            type: "error",
            placement: "topRight",
            top: 84,
            message: t("Action Failed"),
            description: t(
              "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
            ),
            className: styles.alert_error,
          });
        }
      });
  };

  const onCloseModal = () => {
    setButtonDisabled(false);
    onClose();
  };

  return {
    submitButtonRef,
    buttonDisabled,
    setButtonDisabled,
    onModalOk,
    onSubmit,
    onCloseModal,
  };
};

export default useCreateLeadModal;
