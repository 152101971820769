import { ButtonProps, Input, Modal, notification, Table, Typography } from "antd";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { editComission } from '../api/LeadAPI';
import { LeadGetDetails, UserLeadMapping } from '../types/Lead';
import styles from './UpdateCommissionModal.module.css';

type UpdateCommissionRoleProps = {
  lead: LeadGetDetails,
  openModal: boolean,
  onRefetchData: VoidFunction
  onCloseModal: VoidFunction
};

const UpdateCommissionRole = ({
  lead,
  openModal,
  onRefetchData,
  onCloseModal
}: UpdateCommissionRoleProps) => {
  
  const { t } = useTranslation();
  const [tempExistingMembers, setExistingMembers] = useState<UserLeadMapping[]>([])
  const [error, setError] = useState<string>('');
  const [haveMadeChanges, setMadeChanges] = useState(false)
  
  const { Title, Text } = Typography;

  useEffect(() => {
    openModal && 
    setExistingMembers(lead.members)
  }, [openModal, lead])

  useEffect(() => {
    setMadeChanges(JSON.stringify(tempExistingMembers) !== JSON.stringify(lead.members))
  }, [tempExistingMembers])

  useEffect(() => {
    const tempSum = tempExistingMembers.reduce((sum, row) => (row.commissionShare ? sum + row.commissionShare : sum), 0);

    if (tempSum !== 100) {
      setError('The changes cannot be saved as the Total commission does not amount to 100%. Please adjust accordingly before attempting to save it.')
    } else {
      setError('')
    }
  }, [
    tempExistingMembers
  ])

  useEffect(() => {
    if(!openModal){
      setMadeChanges(false)
    }
  }, [openModal])

  const onModalCancel = () => onCloseModal()
  
  const onModalOk =  async () => {
    await editComission({
      id: lead.id,
      members: tempExistingMembers,
    }).then(() => {
      onRefetchData()
      notification.open({
        type: "success",
        placement: "topRight",
        top: 84,
        message: t("Commission details saved!"),
        description: `The commission details for ${lead.title} is now updated.`,
        className: styles.alert_success,
      });
      onCloseModal();
      })
      .catch(() => {
        notification.open({
          type: "error",
          placement: "topRight",
          top: 84,
          message: t("Action Failed"),
          description: t(
            "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
          ),
          className: styles.alert_error,
        });
      });
  }

  const handleCommissionChange = (value: string, member: UserLeadMapping) => {
    const tempVal = value.replace(/[^0-9.]/g, '');
    const temp = tempExistingMembers.map((row) => {
      if (row.upn === member.upn) {
        return { ...row, commissionShare: Number(tempVal) };
      }
      return row;
    });
    setExistingMembers(temp)
  };

  const columns = [
    {
      title: t("Member's Name"),
      dataIndex: "upn",
      width: '67%',
      key: "upn",
    },
    {
      title: "Commission Share",
      dataIndex: "commissionShare",
      width: '33%',
      key: "commissionShare",
      render: (value: number, record: UserLeadMapping) => (
        <Input
          status={error && record.role !== 0 ? 'error' : ''}
          value={record.role === 0 ? '' : value}
          className={styles.w_100}
          disabled={record.role === 0}
          onChange={e => handleCommissionChange(e.target.value, record)}
          addonAfter="%"
        />
      ),
    },
  ];

  return (
    <Modal
      width={1000}
      title={<Title level={5}>
        {t('Edit Commission Share')}
      </Title>}
      data-testid="edit-commission-share"
      open={openModal}
      onOk={() => onModalOk()}
      onCancel={() => onModalCancel()}
      cancelText={'Cancel'}
      okText={ t('Save Changes') }
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: !haveMadeChanges || error
        } as ButtonProps
      }
      destroyOnClose
    >
      <Table
        rowKey="userId"
        data-testid="edit-comm-table"
        dataSource={tempExistingMembers}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalCommission = 0;
          pageData.forEach(({ commissionShare }) => {
            if(commissionShare) {
              totalCommission += commissionShare;
            }
          });
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} className={styles.text_end}>
                  <Text className={styles.total_percentage}>Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} className={styles.table_total_percentage}>
                  <Text>{`${totalCommission}`}</Text>
                  <Text>%</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {error && <Text className={styles.error_text} type="danger">{error}</Text>}
    </Modal>
  );
};

export default UpdateCommissionRole;
