import React from "react";
import { Alert, Button, Card, Table, TableColumnsType, Typography } from "antd";
import { LeadMemberRoleEnum } from '../enum/LeadMemberRoleEnum';
import { PermissionHelper } from "../helpers/PermissionHelper";
import { LeadGetDetails, UserLeadMapping } from '../types/Lead';
import styles from "./UserLeadTable.module.css";

import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Permissions } from "../enum/Permissions";
import { LeadHelper } from "../helpers/LeadHelper";

type UserLeadTableProps = {
  permissions: string[], 
  onRefetchData: VoidFunction
  lead: LeadGetDetails,
  setShowAddMember: (val: boolean) => void,
  setShowEditComission: (val: boolean) => void,
  setEditRole: (val: string) => void,
  setDeleteConsultant: (val: UserLeadMapping | null) => void
  setDeleteMember: (val: UserLeadMapping | null) => void
  hasEditPermission: boolean
};

const UserLeadTable = ({
  lead, 
  hasEditPermission,
  permissions,
  setShowAddMember,
  setShowEditComission,
  setEditRole,
  setDeleteMember,
  setDeleteConsultant
}: UserLeadTableProps) => {

const hasPermission = 
  (PermissionHelper.hasPermission(Permissions.LEAD_EDIT_MEMBER_ROLE,permissions)
  && PermissionHelper.hasPermission(Permissions.LEAD_DELETE_MEMBER,permissions)) || lead.isOwner;

const { Text, Title } = Typography;
const { t } = useTranslation();

const columns: TableColumnsType<UserLeadMapping> = [
  {
    title: "Member's Name",
    dataIndex: "upn",
    width: "27%"
  },
  {
    title: "Role",
    dataIndex: "role",
    width: "26%",
    render: (role: number) => <Text> {LeadMemberRoleEnum[role]}</Text>,
  },
  {
    title: "Commission Share",
    dataIndex: "commissionShare",
    width: "26%",
    render: (commissionShare: number, val: UserLeadMapping) => (
      <Text>
        {commissionShare !== null && val.role === 1? `${commissionShare}%` : "N/A"}
      </Text>
    ),
  },
  ... (hasPermission ? [
    {
      title: "Action(s)",
      key: "actions",
      width: "20%",
      render: (val: UserLeadMapping) => { 
        return (
          <div className={styles.actions}>
            {val.userId !== lead?.owner.id && (
            <>
              <Button
                className={styles.no_padding}
                type="link"
                disabled={!(
                  hasEditPermission ||
                  (lead.isOwner &&
                    LeadHelper.isLeadStatusEditable(
                      lead.status,
                      lead.approvalStatus
                    ))
                )}
                onClick={() => {
                  val.id && setEditRole(val.id)
                }}
              >
                {t("Edit Role")}
              </Button>
              <div className={styles.dividerVertical}></div>
              <Button
                className={styles.no_padding}
                type="link"
                danger
                disabled={!(
                  hasEditPermission ||
                  (lead.isOwner &&
                    LeadHelper.isLeadStatusEditable(
                      lead.status,
                      lead.approvalStatus
                    ))
                )}
                onClick={() => {
                  val.role === 0 
                    ? setDeleteConsultant(val)
                    : setDeleteMember(val)
                }}
              >
                {t("Delete")}
              </Button>
            </>
            )}
          </div>
      )},
    },
  ] : [])
];

return (
<>
  <Card>
    <div className={styles.search_wrapper_parent}>
      <Title level={5}>{t("Commission Structure")}</Title>
      {
        hasPermission && (
          <div className={styles.search_wrapper}>  
           <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowAddMember(true)}
              disabled={
                !(
                  hasEditPermission ||
                  (lead.isOwner &&
                    LeadHelper.isLeadStatusEditable(
                      lead.status,
                      lead.approvalStatus
                    ))
                )
              }
            >
              {t("Add Member")}
            </Button>
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => setShowEditComission(true)}
              disabled={lead.members.length < 2 || !(
                hasEditPermission ||
                (lead.isOwner &&
                  LeadHelper.isLeadStatusEditable(
                    lead.status,
                    lead.approvalStatus
                  ))
              )}
            >
              {t("Edit Commission Share")}
            </Button>
          </div>
        )
      }
    </div>

    <Alert
      className={styles.alert_msg}
      message={
        <ul className={styles.list}>
          <li>
            {t("Details Alert 1")}
          </li>
          <li>
            {t("Details Alert 2")}
          </li>
        </ul>
      }
      type="info"
      showIcon={true}
    />

    <Table 
      data-testid="commission-structure-table"
      columns={columns}
      pagination={false}
      dataSource={lead.members}
      rowKey="id"
      summary={(pageData) => {
        let totalCommission = 0;
        pageData.forEach(({ commissionShare }) => {
          if(commissionShare) {
            totalCommission += commissionShare;
          }
        });
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1} className={styles.text_end}>
                <Text className={styles.total_percentage}>Total</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <Text>{`${totalCommission}%`}</Text>
              </Table.Summary.Cell>
              {
                hasPermission && (
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                )
              }
            </Table.Summary.Row>
          </>
        );
      }}
    />
    <Text className={styles.mt_16}>
      Refer to 
      <a target="_blank" href="/about-incentives">{` Sales Commission Policy on Confluence `}</a>
      for more details.
    </Text>
  </Card>
</>
);
}

export default UserLeadTable;