import axios from 'axios';
import { initInterceptors } from './Interceptors';

const getApiTimeout = () => {
    return ((isNaN(Number(process.env.REACT_APP_API_TIMEOUT)) ? 300 : Number(process.env.REACT_APP_API_TIMEOUT)) * 1000);
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true
    },
    timeout: getApiTimeout(),
});

const pca = initInterceptors(api);

const getTenantId = async (): Promise<string> => {
    await pca.initialize();
    const response = await api.get('/tenant', { params: { tid: pca.getActiveAccount()?.tenantId } });
    return response.data.data.tenantId as string;
}

const get = async (endpoint: string, parameters: Object) => {
    const tenantId = await getTenantId();
    const response = await api.get(`${tenantId}${endpoint}`, { params: parameters });
    return response.data;
}

const post = async (endpoint: string, parameters: Object) => {
    const tenantId = await getTenantId();
    const response = await api.post(`${tenantId}${endpoint}`, parameters);
    return response.data;
}

const put = async (endpoint: string, parameters: object) => {
    const tenantId = await getTenantId();
    const response = await api.put(`${tenantId}${endpoint}`, parameters);
    return response.data;
}

const deleteFn = async (endpoint: string, parameters: object) => {
    const tenantId = await getTenantId();
    const response = await api.delete(`${tenantId}${endpoint}`, { params: parameters } );
    return response.data;
}

const deleteBody = async (endpoint: string, parameters: object) => {
    const tenantId = await getTenantId();
    const response = await api.delete(`${tenantId}${endpoint}`, {data: parameters});
    return response.data;
}

const getNative = async (endpoint: string, parameters: Object) => {
    const response = await api.get(endpoint, { params: parameters });
    return response.data;
}

export { get, post, put, deleteFn, getNative, getApiTimeout, deleteBody };
