import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  ModalProps as AntDModalProps,
  ButtonProps,
  Form,
  Input,
  notification,
  Select,
  Typography,
} from "antd";
import Modal from "./Modal";
import styles from "./UpdateLeadModal.module.css";
import { useTranslation } from "react-i18next";
import useUpdateLeadModal from "../hooks/useUpdateLeadModal";
import { EditLeadFormField, UpdateLeadModalAction } from "../types/Lead";
import { LeadType } from "../enum/LeadType";
import Decimal from "decimal.js";
import { editLead } from "../api/LeadAPI";
import { StatusCode } from "../enum/StatusCode";

const { TextArea } = Input;

interface UpdateLeadModalProps extends AntDModalProps {
  onRefetchData: VoidFunction;
}

const MAX_LENGTH_INPUT = 100;

const MAX_LENGTH_DESCRIPTION = 2000;

const VALID_NUMBER_MAX_2D_REGEX = /^-?\d*(\.\d{0,2})?$/;
const VALID_TWO_OR_MORE_DECIMAL_NUMBER_REGEX = new RegExp(/^-?\d*\.\d{2,}$/);
const DOLLAR_VALUE_REGEX = new RegExp(/^-?\d+(?:,\d{3})*(?:\.\d{2,})?$/);

const LEAD_TYPES = [
  { label: "Operations & Support", value: LeadType.OPERATIONS_AND_SUPPORT },
  {
    label: "Implementation/Consultancy",
    value: LeadType.IMPLEMENTATION_OR_CONSULTANCY,
  },
];

export const UpdateLeadModal = forwardRef<
  UpdateLeadModalAction,
  UpdateLeadModalProps
>(function UpdateLeadModal({ onRefetchData }, ref) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm<EditLeadFormField>();
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalInfo, setModalInfo] = useState<EditLeadFormField>({
    id: "",
    title: "",
    type: LeadType.IMPLEMENTATION_OR_CONSULTANCY,
    contractRef: "",
    totalCommissionValue: "",
    totalContractValue: "",
    description: "",
  });
  const { submitButtonRef, onModalOk, clearTrackingChanges, onFieldsChange } =
    useUpdateLeadModal({ form, modalInfo, setButtonDisabled });

  const fieldsTo2decimal = ["totalContractValue", "totalCommissionValue"];

  const handleFieldsBlur = (event: React.FocusEvent<HTMLFormElement>) => {
    const [, fieldName] = event.target.id.split("_");
    const fieldValue: string = form.getFieldValue(fieldName);

    if (
      fieldValue &&
      fieldsTo2decimal.includes(fieldName) &&
      VALID_NUMBER_MAX_2D_REGEX.test(fieldValue)
    ) {
      const format2decimal = new Decimal(fieldValue).toFixed(2);
      // Format the number with commas as thousands separators
      const formatWithCommas = format2decimal.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      form.setFieldsValue({ [fieldName]: formatWithCommas });
    }
  };

  const handleFieldsOnFocus = (event: React.FocusEvent<HTMLFormElement>) => {
    const [, fieldName] = event.target.id.split("_");
    const fieldValue: string = form.getFieldValue(fieldName);

    if (fieldValue && fieldsTo2decimal.includes(fieldName)) {
      // Remove any non-numeric characters except for the decimal point and minus sign
      const onlyNumber = fieldValue.replace(/[^0-9.-]/g, "");
      form.setFieldsValue({ [fieldName]: onlyNumber });
    }
  };

  const onSubmit = async (value: EditLeadFormField) => {
    await editLead({ ...value, id: modalInfo.id })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Lead details saved!"),
          description: `The lead details for ${value.title} is now updated.`,
          className: styles.alert_success,
        });
        onRefetchData();
        close();
      })
      .catch((error) => {
        const { status, data } = error.response;
        switch (status) {
          case StatusCode.CONFLICT:
            form.setFields([
              {
                name: "title",
                errors: [data.message],
              },
            ]);
            break;
          default:
            notification.open({
              type: "error",
              placement: "topRight",
              top: 84,
              message: t("Action Failed"),
              description: t(
                "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
              ),
              className: styles.alert_error,
            });
            break;
        }
      })
      .finally(() => {
        clearTrackingChanges();
      });
  };

  const handleNumberInputChange =
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;

      value = value.replace(/[^0-9.]/g, '');
      
      if (VALID_TWO_OR_MORE_DECIMAL_NUMBER_REGEX.test(value)) {
        //round down to 2 decimal places
        const numValue = new Decimal(value).toDecimalPlaces(
          2,
          Decimal.ROUND_DOWN
        );
        value = numValue.toFixed(2);
      }
      event.target.value = value;
    };

  const open = useCallback(
    (info: EditLeadFormField) => {
      setModalInfo(info);
      form.setFields(
        Object.entries(info).map(([key, value]) => ({
          name: key,
          value: value,
        }))
      );
      setOpenModal(true);
    },
    [form]
  );

  const close = useCallback(() => {
    setOpenModal(false);
    clearTrackingChanges();
    form.resetFields();
  }, [form]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal
      title={<Title level={5}>Edit Lead Details</Title>}
      data-testid="edit_lead_modal"
      open={openModal}
      onOk={onModalOk}
      onCancel={close}
      okText={t("Save Changes")}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: buttonDisabled,
        } as ButtonProps
      }
      size={"medium"}
      destroyOnClose
    >
      <div className={styles.modal_content_container}>
        <Form
          form={form}
          name="update-lead"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSubmit}
          onFieldsChange={onFieldsChange}
          onBlur={handleFieldsBlur}
          validateTrigger="onSubmit"
          onFocus={handleFieldsOnFocus}
          labelWrap
        >
          <Form.Item
            label={t("Lead Title")}
            name="title"
            rules={[
              {
                required: true,
                message: "This is a required field.",
                validateTrigger: [],
              },
              {
                whitespace: true,
                message: "Please enter at least one non-space character.",
                validateTrigger: [],
              },
            ]}
            validateTrigger="onChange"
          >
            <Input
              data-testid="current_value_input"
              placeholder={t("Enter Lead Title")}
              maxLength={MAX_LENGTH_INPUT}
            />
          </Form.Item>

          <Form.Item
            label={t("Lead Type")}
            name="type"
            rules={[{ required: true, message: "This is a required field." }]}
            validateTrigger="onChange"
          >
            <Select
              className={styles.input}
              placeholder={t("Select Lead Type")}
              options={LEAD_TYPES}
            />
          </Form.Item>

          <Form.Item
            label={t("Deal / Contract Reference")}
            name="contractRef"
            initialValue=""
            rules={[
              {
                whitespace: true,
                message: "Please enter at least one non-space character.",
                validateTrigger: [],
              },
            ]}
          >
            <Input
              data-testid="current_value_input"
              placeholder={t("Enter Deal / Contract Reference")}
              maxLength={MAX_LENGTH_INPUT}
            />
          </Form.Item>

          <Form.Item
            label={t("Total Contract Value")}
            name="totalContractValue"
            rules={[
              {
                pattern: DOLLAR_VALUE_REGEX,
                message: "Please enter a valid numerical value.",
              },
            ]}
          >
            <Input
              addonBefore="$"
              data-testid="current_value_input"
              placeholder={t("Enter Total Contract Value")}
              onInput={handleNumberInputChange()}
            />
          </Form.Item>

          <Form.Item
            label={t("Total Commission Value")}
            name="totalCommissionValue"
            rules={[
              {
                pattern: DOLLAR_VALUE_REGEX,
                message: "Please enter a valid numerical value.",
              },
            ]}
          >
            <Input
              addonBefore="$"
              data-testid="current_value_input"
              placeholder={t("Enter Total Commission Value")}
              onInput={handleNumberInputChange()}
            />
          </Form.Item>

          <Form.Item
            label={t("Description")}
            name="description"
            rules={[
              {
                whitespace: true,
                message: "Please enter at least one non-space character.",
                validateTrigger: [],
              },
            ]}
          >
            <TextArea
              data-testid="current_value_description"
              className={styles.description_input}
              placeholder={t("Enter Lead Description")}
              maxLength={MAX_LENGTH_DESCRIPTION}
              rows={5}
              showCount
            />
          </Form.Item>

          <input
            data-testid="submit-form"
            ref={submitButtonRef}
            type="submit"
            hidden
          />
        </Form>
      </div>
    </Modal>
  );
});

export default UpdateLeadModal;
