import { LeadType } from "../enum/LeadType";
import { UserLeadMapping } from '../types/Lead'
import { get, post, put, deleteBody } from "./API";

const fetchMyLeads = async () => {
  const url = "/leads/my";

  const response = await get(url, {});
  return response;
};

const fetchAllLeads = async () => {
  const url = "/leads/all";

  const response = await get(url, {});
  return response;
};

const fetchLead = async (leadId: string) => {
  const response = await get("/lead", { id: leadId });
  return response;
};

const createLead = async (body: {
  title: string;
  type: LeadType;
  contractRef: string;
  totalContractValue: string;
  totalCommissionValue: string;
  description: string;
}) => {
  const response = await post("/lead/create", body);
  return response;
};

const editLead = async (body: {
  id: string;
  title: string;
  type: LeadType;
  contractRef: string;
  totalContractValue: string;
  totalCommissionValue: string;
  description: string;
}) => {
  const response = await put("/lead/edit", body);
  return response;
};

const editLeadApprovalStatus = async (body: { id: string; approvalStatus: number }) => {
  const response = await put("/lead/approvalStatus/edit", body);
  return response;
};

const editLeadProgress = async (body: {
  id: string;
  status: number;
  estimatedPaymentMilestone: string;
  paymentDate: string;
  payoutMonth: string;
}) => {
  const response = await put("/lead/progress/edit", body);
  return response;
};

const createMember = async (body: {
  id: string, 
  newMembers: UserLeadMapping[],
  existingMembers: UserLeadMapping[],
}) => {
  const response = await put("/member/add", body);
  return response;
};

const fetchUsers = async () => {
  const url = "/user/all";

  const response = await get(url, {});
  return response;
};

const editComission = async (body: {
  id: string;
  members: UserLeadMapping[],
}) => {
  const response = await put("/lead/edit-commission", body);
  return response;
};

const editRole = async (body: {
  id: string;
  members: UserLeadMapping[],
}) => {
  const response = await put("/lead/edit-member-role", body);
  return response;
};

const deleteConsultant = async (body: { id: string, leadId: string }) => {
  const response = await deleteBody("/lead/consultant", body);
  return response;
}

const deleteMember = async (body: { id: string, leadId: string, members: UserLeadMapping[] }) => {
  const response = await deleteBody("/lead/member", body);
  return response;
}

const transferOwner = async (body: { id: string, ownerId: string }) => {
  const response = await put("/lead/transfer-ownership", body);
  return response;
}

export {
  fetchAllLeads,
  fetchMyLeads,
  fetchLead,
  createLead,
  editLead,
  editLeadApprovalStatus,
  editLeadProgress,
  createMember,
  fetchUsers,
  editComission,
  editRole,
  deleteConsultant,
  deleteMember,
  transferOwner
};
