import { AccountInfo } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";
import { TenantConfig } from "../types/TenantConfig";

const addClaims = (claimsChallengeId: string, claims: string): void => {
    sessionStorage.setItem(claimsChallengeId, claims);
};

const getClaims = (claimsChallengeId: string): string | null => {
    return sessionStorage.getItem(claimsChallengeId);
};

const setTenantConfig = (tenantConfig: TenantConfig): void => {
    const result = JSON.stringify(tenantConfig);
    sessionStorage.setItem("p1tstop.tenant.config", result);
}

const getTenantConfig = (): TenantConfig | null => {
    const value = sessionStorage.getItem('p1tstop.tenant.config') ?? '';
    try {
        return JSON.parse(value);
    } catch (e) {
        return null;
    }
}

const clear = (account: AccountInfo) => {
    if (account.idTokenClaims) {
        for (const key in sessionStorage) {
            if (key.startsWith(`cc.${msalConfig().auth.clientId}.${account.idTokenClaims.oid}`)) sessionStorage.removeItem(key);
        }
    }
};

export const StorageHelper = {
    addClaims,
    getClaims,
    setTenantConfig,
    getTenantConfig,
    clear
}