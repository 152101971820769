import { Dispatch, SetStateAction, useCallback, useRef } from "react";
import { FormInstance } from "antd";
import { EditLeadFormField } from "../types/Lead";
import { FieldData } from "rc-field-form/lib/interface";

type Props = {
  form: FormInstance<EditLeadFormField>;
  modalInfo: EditLeadFormField;
  setButtonDisabled: Dispatch<SetStateAction<boolean>>;
};

const useUpdateLeadModal = ({ form, modalInfo, setButtonDisabled }: Props) => {
  const submitButtonRef = useRef<HTMLInputElement>(null);
  const trackingChangesRef = useRef<{ [key: string]: boolean }>({});

  const onFieldsChange = (changedFields: FieldData[], allFields: FieldData[]) => {
    const { name, value } = changedFields[0];
    const changedFieldName = name;
    const changedValue = value;
    const changedKey =
      changedFieldName instanceof Array ? changedFieldName[0] : "";
    const lastValue = modalInfo[changedKey as keyof EditLeadFormField];
    const trackingChanges = trackingChangesRef.current;
    trackingChanges[changedKey] = changedValue !== lastValue;
    trackingChangesRef.current = trackingChanges;
    const hasChanges = Object.keys(trackingChanges).some(
      (key) => trackingChanges[key]
    );
    const isError = form
      .getFieldsError()
      .some((field) => field.errors.length > 0);
    setButtonDisabled(!hasChanges || isError);

    if (changedFields.length > 1) return;

    let fieldToValidate = { ...changedFields[0] };

    if (fieldToValidate?.errors?.[0]) {
      fieldToValidate = { ...fieldToValidate, errors: [] };
      const updatedFields = allFields.map((field: FieldData) => {
        if (field.name?.[0] === fieldToValidate.name?.[0]) {
          return fieldToValidate;
        }
        return field;
      });
      const hasErrors = updatedFields.some((field: FieldData) => field.errors && field.errors.length > 0);
      setButtonDisabled(hasErrors);
      form.setFields([...allFields, fieldToValidate])
    }
  };

  const onModalOk = () => {
    submitButtonRef.current?.click();
  };

  const clearTrackingChanges = useCallback(() => {
    trackingChangesRef.current = {};
    setButtonDisabled(true);
  }, [setButtonDisabled]);

  return { submitButtonRef, onFieldsChange, onModalOk, clearTrackingChanges };
};

export default useUpdateLeadModal;
