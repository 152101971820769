import React from "react";
import { Button, Card, Tooltip, Typography, Tag } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { LeadHelper } from "../helpers/LeadHelper";
import { useTranslation } from "react-i18next";
import { LeadStatus } from "../enum/LeadStatus";

import styles from "./LeadProgressCard.module.css";

type LeadProgressCardProps = {
  hasEditPermission: boolean;
  lead: {
    status: LeadStatus;
    estimatedPaymentMilestone: string;
    paymentDate: string;
    payoutMonth: string;
  };
  onOpenEditModal: VoidFunction;
};

function LeadProgressCard({
  hasEditPermission,
  lead,
  onOpenEditModal,
}: LeadProgressCardProps) {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  return (
    <Card
      title={<Title level={5}>{t("Lead Progress")}</Title>}
      extra={
        hasEditPermission && (
          <Button
            onClick={onOpenEditModal}
            type="primary"
            icon={<EditOutlined />}
          >
            Edit Lead Progress
          </Button>
        )
      }
    >
      <div className={styles.card_body_container}>
        <div>
          <div className={styles.lead_progress_header_with_tooltip}>
            <Text>{t("Lead Status")}:</Text>
            <Tooltip
              placement="right"
              title={
                "'Submission' refers to the formal final submission after the budgetary quotation stage"
              }
            >
              <InfoCircleOutlined className={styles.tooltip_icon} />
            </Tooltip>
          </div>
          <Tag color={LeadHelper.getStatusColor(lead.status)} key={lead.status}>
            {t(LeadHelper.getStatusTagName(lead.status))}
          </Tag>
        </div>
        <div>
          <Text className={styles.lead_progress_header}>
            {t("Estimated Payment Milestone from Client")}:
          </Text>
          <Text>
            {lead.estimatedPaymentMilestone !== ""
              ? lead.estimatedPaymentMilestone
              : "-"}
          </Text>
        </div>
        <div>
          <Text className={styles.lead_progress_header}>
            {t("Actual Payment Date from Client")}:
          </Text>
          <Text>{lead.paymentDate !== "" ? lead.paymentDate : "-"}</Text>
        </div>
        <div>
          <Text className={styles.lead_progress_header}>
            {t("Payout Month to Members")}:
          </Text>
          <Text>{lead.payoutMonth !== "" ? lead.payoutMonth : "-"}</Text>
        </div>
      </div>
    </Card>
  );
}

export default LeadProgressCard;
