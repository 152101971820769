import React from 'react'
import { Alert, Input, Select, Table, Typography } from "antd";
import { useTranslation } from 'react-i18next';

import { Lead, UserLeadMapping } from '../types/Lead';
import styles from './UpdateCommissionRole.module.css';
import { LeadMemberRoleEnum } from '../enum/LeadMemberRoleEnum'

const { Text } = Typography;
const { Option } = Select;

type UpdateCommissionRoleProps = {
  lead: Lead,
  hideRoleColumn: boolean,
  comissionError: string,
  tempExistingMembers: UserLeadMapping[], 
  tempNewMember?: UserLeadMapping[],
  setNewMember?: (val: UserLeadMapping[]) => void,
  setExistingMembers: (val: UserLeadMapping[]) => void,
  setMadeChanges: (val: boolean) => void,
};


const UpdateCommissionRole = ({
  lead,
  tempNewMember = [],
  setNewMember,
  hideRoleColumn,
  tempExistingMembers,
  setExistingMembers,
  setMadeChanges,
  comissionError
}: UpdateCommissionRoleProps) => {
  
  const { t } = useTranslation();

  const handleCommissionChange = (value: string, member: UserLeadMapping) => {
    const tempVal = value.replace(/[^0-9.]/g, '');
    if(member.id){
      const temp = tempExistingMembers.map((row) => {
        if (row.upn === member.upn) {
          return { ...row, commissionShare: Number(tempVal)};
        }
        return row;
      });
      setMadeChanges(true)
      setExistingMembers(temp)
    } else {
      const temp = tempNewMember.map((row) => {
        if (row.upn === member.upn) {
          return { ...row, commissionShare: Number(tempVal)};
        }
        return row;
      });
      setMadeChanges(true)
      setNewMember?.(temp)
    }
  };

  const handleRoleChange = (value: number, member: UserLeadMapping) => {
    if(member.id){
      const temp = tempExistingMembers.map((row) => {
        if (row.upn === member.upn) {
          return { ...row, role: value, commissionShare: value === 0 ? 0 : row.commissionShare || 0 };
        }
        return row;
      });
      setMadeChanges(true)
      setExistingMembers(temp)
    } else {
      const temp = tempNewMember.map((row) => {
        if (row.upn === member.upn) {
          return { ...row, role: value, commissionShare: value === 0 ? 0 : row.commissionShare  || 0 };
        }
        return row;
      });
      setMadeChanges(true)
      setNewMember?.(temp)
    }
  };

  const columns = [
    {
      title: t("Member's Name"),
      dataIndex: "upn",
      width: !hideRoleColumn ? '34%' : '67%',
      key: "upn",
    },
    ... !hideRoleColumn ? [
      {
        title: "Role",
        dataIndex: "role",
        width: '33%',
        key: "role",
        render: (value: number, record: UserLeadMapping) => (
          <>
          {
            record.userId === lead.owner.id && (
              <Text className={styles.owner_label}>{LeadMemberRoleEnum[record.role]}</Text>
            )
          }
          {
            record.userId !== lead.owner.id && (
              <Select
                value={value}
                className={styles.w_100}
                onChange={(val) => handleRoleChange(+val, record)}
              >
                <Option value={0}>Consultant</Option>
                <Option value={1}>Member</Option>
              </Select>
            )
          }
          </>
        ),
      }
    ] : [],
    {
      title: "Commission Share",
      dataIndex: "commissionShare",
      width: '33%',
      key: "commissionShare",
      render: (value: number, record: UserLeadMapping) => (
        <Input
          data-testid="number-input"
          status={comissionError && record.role !== 0 ? 'error' : ''}
          value={record.role === 0 ? '' : value}
          className={styles.w_100}
          disabled={record.role === 0}
          onChange={e => handleCommissionChange(e.target.value, record)}
          addonAfter="%"
        />
      ),
    },
  ];

  return (
    <> 
      {
        !hideRoleColumn && (
          <Alert
            message={t('Alert Consultant Not Eligible')}
            type="info"
            className={styles.alert_consultant}
            showIcon
          />
        )
      }
      <Table
        data-testid="edit-role-comm-table"
        rowKey="userId"
        dataSource={[
          ... tempExistingMembers,
          ... tempNewMember,
        ]}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalCommission = 0;
          pageData.forEach(({ commissionShare }) => {
            if(commissionShare) {
              totalCommission += commissionShare;
            }
          });
          return (
            <>
              <Table.Summary.Row>
                {
                  !hideRoleColumn && (
                    <>
                      <Table.Summary.Cell index={0}></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className={styles.text_end}>
                        <Text className={styles.total_percentage}>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text>{`${totalCommission}%`}</Text>
                      </Table.Summary.Cell>
                    </>
                  )
                }
                {
                  hideRoleColumn && (
                    <>
                      <Table.Summary.Cell index={0} className={styles.text_end}>
                        <Text className={styles.total_percentage}>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} className={styles.table_total_percentage}>
                        <Text>{`${totalCommission}`}</Text>
                        <Text>%</Text>
                      </Table.Summary.Cell>
                    </>
                  )
                }
              </Table.Summary.Row>
            </>
          );
        }}
      />
      {comissionError && <Text className={styles.error_text} type="danger">{comissionError}</Text>}
    </>
  );
};

export default UpdateCommissionRole;
