import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import {
  ModalProps as AntDModalProps,
  ButtonProps,
  DatePicker,
  Form,
  notification,
  Select,
  Typography,
} from "antd";
import Modal from "./Modal";
import { useTranslation } from "react-i18next";
import useUpdateLeadProgressModal from "../hooks/useUpdateLeadProgressModal";
import {
  EditLeadProgressFormField,
  UpdateLeadProgressModalAction,
} from "../types/Lead";
import { editLeadProgress } from "../api/LeadAPI";
import { LeadStatus } from "../enum/LeadStatus";
import styles from "./UpdateLeadProgressModal.module.css";
import moment, { Moment } from "moment";

interface UpdateLeadProgressModalProps extends AntDModalProps {
  onRefetchData: VoidFunction;
}

const LEAD_STATUSES = [
  { label: "Pending Submission", value: LeadStatus.PENDING_SUBMISSION },
  { label: "Bid Submitted", value: LeadStatus.BID_SUBMITTED },
  { label: "Bid Successful", value: LeadStatus.BID_SUCCESSFUL },
  { label: "Bid Failed", value: LeadStatus.BID_FAILED },
  { label: "Inactive", value: LeadStatus.INACTIVE },
];

export const UpdateLeadProgressModal = forwardRef<
  UpdateLeadProgressModalAction,
  UpdateLeadProgressModalProps
>(function UpdateLeadProgressModal({ onRefetchData }, ref) {
  const { Title } = Typography;
  const { t } = useTranslation();
  const [form] = Form.useForm<EditLeadProgressFormField>();
  const [openModal, setOpenModal] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalInfo, setModalInfo] = useState<
    EditLeadProgressFormField & { title: string }
  >({
    id: "",
    title: "",
    status: LeadStatus.PENDING_SUBMISSION,
    estimatedPaymentMilestone: "",
    paymentDate: "",
    payoutMonth: "",
  });
  const { submitButtonRef, onModalOk, clearTrackingChanges } =
    useUpdateLeadProgressModal({
      form,
      modalInfo,
      setButtonDisabled,
    });

  const onFieldChange = () => {
    setButtonDisabled(
      form.getFieldsError().some((field) => field.errors.length > 0)
    );
  };

  const handleMonthPickerChange = (
    moment: Moment | null,
    dateString: string
  ) => {
    form.setFieldValue("payoutMonth", dateString);
    setModalInfo({ ...modalInfo, payoutMonth: dateString });
  };

  const onSubmit = async (value: EditLeadProgressFormField) => {
    await editLeadProgress({
      ...value,
      id: modalInfo.id,
    })
      .then(() => {
        notification.open({
          type: "success",
          placement: "topRight",
          top: 84,
          message: t("Lead Progress details saved!"),
          description: `The lead progress for ${modalInfo.title} is now updated.`,
          className: styles.alert_success,
        });
        onRefetchData();
        close();
      })
      .catch((error) => {
        const { status } = error.response;
        switch (status) {
          default:
            notification.open({
              type: "error",
              placement: "topRight",
              top: 84,
              message: t("Action Failed"),
              description: t(
                "Oops, something went wrong. Please try again, and contact support for assistance if the issue persists"
              ),
              className: styles.alert_error,
            });
            break;
        }
      })
      .finally(() => {
        clearTrackingChanges();
      });
  };

  const open = useCallback(
    (info: EditLeadProgressFormField & { title: string }) => {
      setModalInfo(info);
      form.setFields(
        Object.entries(info).map(([key, value]) => ({
          name: key,
          value: value,
        }))
      );
      setOpenModal(true);
    },
    [form]
  );

  const close = useCallback(() => {
    setOpenModal(false);
    clearTrackingChanges();
  }, [form]);

  useImperativeHandle(ref, () => ({ open, close }), [open, close]);

  return (
    <Modal
      title={<Title level={5}>Edit Lead Progress</Title>}
      data-testid="edit_lead_progress_modal"
      open={openModal}
      onOk={onModalOk}
      onCancel={close}
      okText={t("Save Changes")}
      okButtonProps={
        {
          "data-testid": "button-submit",
          disabled: buttonDisabled,
        } as ButtonProps
      }
      size={"medium"}
      destroyOnClose
    >
      <div className={styles.modal_content_container}>
        <Form
          form={form}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 20 }}
          onFinish={onSubmit}
          onFieldsChange={onFieldChange}
          labelWrap
        >
          <Form.Item
            label={t("Lead Status")}
            name="status"
            rules={[{ required: true, message: "This is a required field." }]}
          >
            <Select
              className={styles.input}
              placeholder={t("Select Lead Status")}
              options={LEAD_STATUSES}
            />
          </Form.Item>
          <Form.Item
            label="Estimated Payment Milestone from Client"
            name="estimatedPaymentMilestone"
            valuePropName="date"
          >
            <DatePicker
              picker="month"
              placeholder="Select Estimated Payment Milestone from Client"
              className={styles.datepicker_field}
              value={
                modalInfo.estimatedPaymentMilestone &&
                modalInfo.estimatedPaymentMilestone !== ""
                  ? moment(modalInfo.estimatedPaymentMilestone, "MMM YYYY")
                  : null
              }
              onChange={(m, s) => {
                form.setFieldValue("estimatedPaymentMilestone", s);
                setModalInfo({ ...modalInfo, estimatedPaymentMilestone: s });
              }}
              format="MMM YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Actual Payment Date from Client"
            name="paymentDate"
            valuePropName="date"
          >
            <DatePicker
              placeholder="Select Actual Payment Date from Client"
              className={styles.datepicker_field}
              value={
                modalInfo.paymentDate && modalInfo.paymentDate !== ""
                  ? moment(modalInfo.paymentDate, "DD MMM YYYY")
                  : null
              }
              onChange={(m, s) => {
                form.setFieldValue("paymentDate", s);
                setModalInfo({ ...modalInfo, paymentDate: s });
              }}
              format="DD MMM YYYY"
            />
          </Form.Item>
          <Form.Item
            label="Payout Month"
            name="payoutMonth"
            valuePropName="date"
          >
            <DatePicker
              picker="month"
              placeholder="Select Payout Month"
              className={styles.datepicker_field}
              value={
                modalInfo.payoutMonth && modalInfo.payoutMonth !== ""
                  ? moment(modalInfo.payoutMonth, "MMM YYYY")
                  : null
              }
              onChange={handleMonthPickerChange}
              format="MMM YYYY"
            />
          </Form.Item>

          <input
            data-testid="submit-form"
            ref={submitButtonRef}
            type="submit"
            hidden
          />
        </Form>
      </div>
    </Modal>
  );
});

export default UpdateLeadProgressModal;
